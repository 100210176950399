<template>
    <div>
        <v-snackbar v-if="isLesserThanResolution" v-model="snackbar" :timeout="timeout" absolute bottom color="success"
            outlined class="">
            <!-- <v-card> -->
            <v-icon color="green" v-if="success">
                "mdi mdi-check-circle
            </v-icon>
            <v-icon color="red" v-if="failure">
                mdi mdi-close-circle
            </v-icon>
            {{ toasttext }}

            <template v-slot:action="{ attrs }">
                <v-btn icon color="green" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi mdi-close</v-icon>
                </v-btn>
            </template>
            <!-- </v-card> -->
        </v-snackbar>
        <v-snackbar v-else v-model="snackbar" :timeout="timeout" absolute top color="success" outlined right class="">
            <!-- <v-card> -->
            <v-icon color="green" v-if="success">
                "mdi mdi-check-circle
            </v-icon>
            <v-icon color="red" v-if="failure">
                mdi mdi-close-circle
            </v-icon>
            {{ toasttext }}

            <template v-slot:action="{ attrs }">
                <v-btn icon color="green" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi mdi-close</v-icon>
                </v-btn>
            </template>
            <!-- </v-card> -->
        </v-snackbar>
        <v-container class="body-card">

            <v-card width="" height="" class="card-sty1  ">
                <v-progress-linear absolute color='#0037B7' height="12" value="60"></v-progress-linear>
                <v-toolbar color="transparent" dense flat class="mt-5 mb-3">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <img :src="require('@/assets/Zebu Logo.svg')" class="ml-1" contain width="90px">

                        </v-col>
                        <v-col cols="3" class="d-none d-lg-block">

                        </v-col>
                        <v-col class="stageselect" cols="3">
                            <v-select outlined style="" dense @change="stageToo()" item-text="val" item-value="key"
                                item-disabled="disable" :items="stagesitemsis" v-model="stageis" flat solo
                                background-color="#ffffff" class="txtfield elevation-0 rounded-pill mr-4 ml-auto mt-3 "
                                label="Stage" hide-details append-icon="mdi-chevron-down">
                            </v-select>

                        </v-col>
                    </v-row>

                </v-toolbar>
                <v-row class="px-8">
                    <v-col lg="6" class="d-none d-lg-block">
                        <v-card width="100%" class="rounded-0 d-none d-lg-block elevation-0">
                            <img v-for="(image, index) in images" :key="index" :src="image"
                                :style="{ display: index === currentIndex ? 'block' : 'none' }" style="height: 26em;
  display: flex;
  align-items: center;
  justify-content: center" width="100%" />
                        </v-card>
                    </v-col>
                    <v-col lg="6" class="pa- pl-2 cols-sm-12">
                        <v-card width="100%" height="65vh" class="rounded-0 elevation-0" style="overflow: scroll; overflow-x: hidden;">
                            <div class=" pa-0" style="padding-left:20px;padding-right:30px;">
                                <p class="title font-weight-bold mb-2">Exchange Selection</p>
                                <v-row style="padding-left:10px;">
                                    <v-col cols="6" sm="4" md="4">
                                        <v-checkbox class="lable" v-model="exchangeSelect" label="NSE Cash" color="black"
                                            value="NSE_CASH"  :rules="[v => !!v || 'Required',]" hide-details></v-checkbox>
                                            <v-checkbox class="lable" v-model="exchangeSelect" label="NSE FNO" color="black"
                                            value="NSE_FNO"  :rules="[v => !!v || 'Required',]" hide-details></v-checkbox>
                                    </v-col>
                                    <v-divider vertical class="my-5 mt-8"></v-divider>
                                    <v-col cols="6" sm="4" md="4">
                                      
                                        <v-checkbox class="lable" v-model="exchangeSelect" label="BSE Cash" color="black"
                                        value="BSE_CASH"  :rules="[v => !!v || 'Required',]" hide-details></v-checkbox>

                                        <v-checkbox class="lable " v-model="exchangeSelect" label="BSE FNO" color="black"
                                            value="BSE_FNO"  :rules="[v => !!v || 'Required',]" hide-details></v-checkbox>

                                    </v-col>
                                    <v-divider vertical class="my-5 mt-8"></v-divider>

                                    <!-- <v-col cols="6" sm="4" md="4">
                                       

                                    </v-col>
                                   
                                    <v-col cols="6" sm="4" md="4">
                                      
                                    </v-col> -->
                                    <v-col cols="6" sm="4" md="4">
                                        <v-checkbox class="lable  " v-model="exchangeSelect" label="MCX" color="black"
                                            value="MCX" :rules="[v => !!v || 'Required',]" hide-details></v-checkbox>

                                    </v-col>
                                </v-row>
                               

                                <v-text-field color="black" hide-spin-buttons dense outlined rounded prepend-inner-icon="mdi mdi-chart-line"
                                    flat v-on:keyup="yearvalidation()" :rules="[rulesofyear]" prefix="      " class=" lable"
                                    style="padding-top:25px; width: 93%;" count="2" type="number"
                                    v-model="tradingExperience" label="TradingExperience"  
                                    @input="$v.tradingExperience.$touch()" @blur="$v.tradingExperience.$touch()" required>
                                </v-text-field>


                                <v-row class="row pt-4" style="padding:0;margin-top:-15px">
                                    <v-col lg="6" class="cols-sm-12">

                                        <v-select style="width: 93%;" color="black" hide-spin-buttons dense outlined rounded
                                            prepend-inner-icon="mdi mdi-account-school" flat
                                            :rules="[v => !!v || 'Qualification is required',]" prefix="      "
                                            v-model="qualification" class="lable" :items="qualificationList"
                                            label="Qualification"  
                                            @input="$v.qualification.$touch()" @blur="$v.qualification.$touch()"
                                            required></v-select>
                                    </v-col>
                                    <v-col lg="6" class="cols-sm-12">

                                        <v-select color="black" hide-spin-buttons dense outlined rounded
                                            prepend-inner-icon="mdi mdi-account-hard-hat" flat
                                            :rules="[v => !!v || 'Occupation is required',]" prefix="      " class="lable"
                                            v-model="occupation" style="width: 93%;" :items="occupationList"
                                            label="Occupation"   @input="$v.occupation.$touch()"
                                            @blur="$v.occupation.$touch()" required></v-select>
                                    </v-col>
                                    <v-col lg="12" class="cols-sm-12">

                                        <v-text-field    color="black" hide-spin-buttons dense outlined rounded prepend-inner-icon="mdi mdi-chart-line"
                                            flat   class=" lable mt-n10"
                                            style="padding-top:25px; width: 93%;"  
                                            v-model="SpouseName" label="Spouse Name"  
                                            required>
                                        </v-text-field>
                                                                        
                                     </v-col>
                                </v-row>
                                
                                <!-- <v-row v-if="exchangeSelect.includes(`MCX`)" class="row " style="padding:0;margin-top:-50px">
                                    <v-col cols="6" sm="4" md="4" class="cols-sm-12">
                                        <v-checkbox  color="black" class="lable ml-6"
                                            v-model="Married"
                                            label="Married"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="6" sm="4" md="4" class="cols-sm-12">
                                        <v-checkbox  color="black" class="lable"
                                            v-model="Unmarried"
                                            label="Unmarried"
                                        ></v-checkbox>
                                    </v-col>

                                </v-row> -->
                                <!-- <v-text-field v-if="gender == 'Female' && Married == true"  color="black" hide-spin-buttons dense outlined rounded prepend-inner-icon="mdi mdi-chart-line"
                                    flat   class=" lable mt-n10"
                                    style="padding-top:25px; width: 93%;"  
                                    v-model="HusbandName" label="Husband Name"  
                                      required>
                                </v-text-field> -->
                                <!-- <v-btn rounded color="black" @click="segmentSelection()" class="otpbtn white--text py-5 my-4 "
                                    style="width: 100%;">Continue</v-btn> -->
                                <v-btn style="width: 93%;" rounded color="black"
                                    class="fltbtn otpbtn white--text py-6 my-4 " :loading="loading2" :disabled="loading2"
                                    @click="segmentSelection()">
                                    Continue
                                    <template v-slot:loader>
                                        <span>Loading...</span>
                                    </template>
                                </v-btn>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
    </div>
</template>
<script>
import axios from 'axios';
import api from '@/api';
import imagePath  from '../assets/inv1.svg'
import imagePath1  from '../assets/inv2.svg'
import imagePath2  from '../assets/inv3.svg'
import imagePath3  from '../assets/inv4.svg'
import imagePath4  from '../assets/inv5.svg'

export default {
    data: () => ({
        rulesofyear: null,
        loading2: false,
        // Sign Up
        mobile_num: null,
        otp: '',
        stageis: 5,
        stagesitemsis: [{ key: 1, val: 'Sign up' }, { key: 2, val: 'Identity verification' }, { key: 3, val: 'Digilocker' }, { key: 4, val: 'Bank details' }, { key: 5, val: "Segment selection" }, { key: 6, val: 'Document upload' }, { key: 7, val: 'e-sign' },],

        otpdisp: true,
        snackbar: false,
        timerEnabled: false,
        timerCount: 120,
        HusbandName : '',
        resendText: false,
        SpouseName : '',
        toasttext: 'null',
        cardloader: false,
        errorOTPDisp: false,
        isLesserThanResolution: false,
        numberStart: true,
        otpstart: true,
        otptext: '',
        // test\
        mainurl: null,

        accountTypeItems: ['Saving Account', 'Current Account'],
        accountType: null,
        accNo: null,
        ifsc: null,
        retrybank: false,

        retrybankacc: false,
        retryCount: 0,
        fileattach1st: false,
        opencam: false,
        // cam
        isCameraOpen: false,
        isPhotoTaken: false,
        isShotPhoto: false,
        isLoading: false,
        link: '#',
        photoData: null,
        localHash: null,
        dialog1: false,
        panStatus: null,
        pennyVerify: '',
        acctype: null,
        eduFile: false,
        DisplayData: [],
        aadhaApi: '',
        fatherNameval: '',
        corFather: '',
        bankApi: '',
        Slidervalue: 1,
        eduApi: '',
        // fatherNameval : '',
        // from kyc
        loader: null,
        panLoader: null,
        loading: false,
        // mobile_num: null,
        //errorMobNumber:false,
        logstatus: false,
        reference: null,
        Unmarried :false,
        Married :false,
        // timerCount: 120,
        // timerEnabled: false,
        // resendText: false,
        email: null,
        emailDisp: false,
        emailOtpDisp: false,
        otpemail: null,
        // otp: null,
        checkbox: false,
        invalid: null,
        text: `You will receive an OTP on your number`,
        loadertext: "Verify",
        timeout: 1000,
        // otpdisp: true,
        mobdisp: true,
        isShowing: false,
        activePicker: null,
        date: null,
        menu: false,
        panDetaildisp: true,
        panFetchDetail: false,
        panInput: null,
        // accNo:s null,
        // ifsc: null,
        bank_name: null,
        bank_branch: null,
        bank_micr: null,
        enabledRegisteredAP: false,
        regMemberName: null,
        exchangeSelect: [],
        tradingExperience: '',
        qualificationList: ['Highschool', 'Graduation', 'Post Graduation', 'Doctrate', 'Prof. Degree', 'Under High School', 'Illiterate-Other'],
        occupationList: ['Private Sector', 'Public Sector', 'Government Service', 'Business', 'Professional Agriculturist', 'Retired', 'Housewife', 'Student', 'Forex Dealer', 'Other'],
        qualification: null,
        occupation: null,
        errorDescPAN: false,
        KycFetchDisp: false,
        dob: null,
        aadharNo: null,
        add1: null,
        add2: null,
        add3: null,
        add4: null,
        address: null,
        country: null,
        district: null,
        state: null,
        pinCode: null,
        fatherName: null,
        gender: '',
        name: null,
        panFile: null,
        // aadharFile:null,
        bankFile: null,
        educationFiler: null,
        kycLoader: false,
        checkboxEsign: false,
        digioFile: null,
        digioMail: null,
        ekycStatus: false,
        step: 1,
        bankverifystatus: null,

        digiInformationShow: false,
        dialog: false,
        isDarkModeEnabled: false,
        // errorOTPDisp: false,
        erroreOTPDisp: false,
        // cardloader: false,
        ifscErrorDesc: false,
        // snackbar: false,
        // toasttext: 'null',
        // timeout: 2000,
        panInputLength: false,
        success: false,
        failure: false,
        images: [
      // 'http://zebu-ekcy.web.app/img/inv3.bf76f1c4.svg',
      // 'http://zebu-ekcy.web.app/img/inv5.fb7c3474.svg',
      // 'http://zebu-ekcy.web.app/img/trd3.2bc52f23.svg',
      // 'http://zebu-ekcy.web.app/img/inv4.6e5ffeda.svg',
      // 'http://zebu-ekcy.web.app/img/inv4.6e5ffeda.svg',
      imagePath,imagePath1,imagePath2,imagePath3,imagePath4
      // 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',

      // 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',

      // 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',

    ],

        currentIndex: 0,

    }),
    created() {
        this.startAutoChange();
        let stages = Number(localStorage.getItem("stageis"));
        if (typeof stages === 'number') {
            for (let s = 0; s < this.stagesitemsis.length; s++) {
                this.stagesitemsis[s]['disable'] = this.stagesitemsis[s].key > stages ? true : false;
                // //console.log(this.stagesitemsis[s]['disable'],"this.stagesitemsis[s]['disable']")
            }
            if (stages < this.stageis) {
                this.stageis = stages;
                this.stageToo();
            }
        }

        else {
            this.$router.push("/");
        }
        const thresholdResolution = 767;

        const screenWidth = window.innerWidth;

        if (screenWidth < thresholdResolution) {
            this.isLesserThanResolution = true;
        } else {
            this.isLesserThanResolution = false;

        }
    },
    methods: {
        startAutoChange() {
            setInterval(() => {
                this.currentIndex = (this.currentIndex + 1) % 4; // Increment index and loop back to 0
            }, 3000); // Change every 3 seconds (3000 milliseconds)
        },
        yearvalidation() {
            if (this.tradingExperience >= 50) {
                this.rulesofyear = "Max span is 50 years"
            } else if (this.tradingExperience <= 50) {
                this.rulesofyear = null
            }
        },
        stageToo() {
            if (this.stageis == 1) {
                this.$router.push("/");
            }
            else if (this.stageis == 2) {
                this.$router.push("/pan");
            }
            else if (this.stageis == 3) {
                this.$router.push("/aadhar");
            }
            else if (this.stageis == 4) {
                this.$router.push("/bank");
            }
            else if (this.stageis == 5) {
                this.$router.push("/segments");
            }
            else if (this.stageis == 6) {
                this.$router.push("/fileupload");
            }
            else if (this.stageis == 7) {
                this.$router.push("/preview");
            } else if (this.stageis == 8) {
                this.$router.push("/submit");
            }
            // else if (this.stageis == 8) {
            //     this.$router.push("");
            // }
        },
        segmentSelection() {
            // this.$v.$touch()
            ////console.log(this.mobile_num, this.exchangeSelect, this.exchangeSelect.length, this.regMemberName, this.qualification != null, this.occupation != null)
            this.loader = 'loading2'
            this.mobile_num = atob(localStorage.getItem('hash'))
            if (sessionStorage.getItem('segment')) {
                //console.log("ENTER IN TO CHAGE")
                if (this.mobile_num && this.exchangeSelect.length != 0 && this.tradingExperience <= 50 && this.tradingExperience != '' && this.qualification != null && this.occupation != null && this.SpouseName != null ) {
                    //   this.$v.$reset() 
                    //console.log(this.exchangeSelect, "KJKJKJ");
                    var config = {
                        method: 'post',
                        url: api.api_url + '/segmentUpdation?mob=' + this.mobile_num + '&seg=' + this.exchangeSelect + '&tradeExp=' + this.tradingExperience + '&qualification=' + this.qualification + '&occupation=' + this.occupation + '&change=' + 'update',
                        headers: {}
                    };
                    var axiosThis = this
                    axios(config)
                        .then(function (response) {
                            ////console.log(JSON.stringify(response.data));

                            ////console.log("ewq", response.data);
                            axiosThis.acctype = response.data['RAAP']
                            sessionStorage.setItem('acctype', axiosThis.acctype)
                            axiosThis.pennyVerify = response.data.pennyDropVerify
                            sessionStorage.setItem('pennyVerify', axiosThis.pennyVerify)
                            axiosThis.panStatus = response.data.pan
                            sessionStorage.setItem('panStatus', axiosThis.panStatus)


                            ////console.log("type121", axiosThis.panStatus);
                            ////console.log("type121", axiosThis.acctype);
                            ////console.log("type121", axiosThis.pennyVerify);

                            // if (axiosThis.acctype == "RA") {
                            //   axiosThis.eduFile = false
                            // axiosThis.step = 7

                            // } else {
                            // axiosThis.step = 6

                            // }
                            if ((axiosThis.acctype == "RA") && (axiosThis.pennyVerify == 'yes') && (axiosThis.panStatus == true)) {
                                let formData = new FormData();

                                formData = ''


                                //console.log('>> formData falseeeeeee>> ');

                                // You should have a server side REST API 
                                axios.post(api.api_url + '/fileUpload?mob=' + atob(localStorage.getItem('hash')),
                                    formData, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }
                                ).then(function (response) {
                                    ////console.log('SUCCESS!!');
                                    axiosThis.DisplayData = response.data
                                    // axiosThis.$router.push('/preview') 
                                    sessionStorage.setItem('DisplayData', JSON.stringify(axiosThis.DisplayData));
                                    // this.$router.push("/");

                                    // axios.Slidervalue = 84

                                    // aadhae
                                    axiosThis.aadharFile = axiosThis.DisplayData[0].adharimg
                                    ////console.log("AL", axiosThis.aadharFile)
                                    // axiosThis.aadhaApi = axiosThis.aadharFile.split('/').slice(-5).join('/');
                                    // axiosThis.aadhaApi = axiosThis.aadharFile.split('/').slice(-4).join('/');
                                    // axiosThis.aadhaApi = axiosThis.aadharFile.split('/').slice(-4).join('/');
                                    // //console.log("asdf", axiosThis.aadharFile.split('ap-zebu-BE')[1])
                                    if (axiosThis.aadharFile) {
                                        // Check if axiosThis.panApi is defined
                                        const splitResult = axiosThis.aadharFile.split('/ap-backend/');
                                        //console.log("splitResult", splitResult);
                                        if (splitResult.length > 1) {
                                            // Check if the split operation was successful
                                            axiosThis.aadhaApi = `${api.api_url}/${splitResult[1]}`;
                                        } else {
                                            // Handle the case where the split operation failed
                                            //console.error("Split operation failed.");
                                        }
                                    } else {
                                        // Handle the case where axiosThis.panApi is undefined
                                        //console.error("axiosThis.panApi is undefined.");
                                    }
                                    // axiosThis.aadhaApi = `${api.api_url}${axiosThis.aadharFile.split('/ap-backend/')[1]}`
                                    //console.log("asdf PPP", axiosThis.aadhaApi)

                                    sessionStorage.setItem('RRRRR', axiosThis.aadhaApi);

                                    ////console.log("SAL", axiosThis.aadhaApi);
                                    // pan
                                    axiosThis.panFile = axiosThis.DisplayData[0].panimgloc
                                    ////console.log("PL", axiosThis.panFile)
                                    ////axiosThis.panApi = axiosThis.panFile.split('/').slice(-5).join('/');
                                    // axiosThis.panApi = axiosThis.panFile.split('/').slice(-5).join('/');
                                    // axiosThis.aadhaApi = axiosThis.aadharFile.split('/').slice(-4).join('/');
                                    if (axiosThis.panFile) {
                                        // Check if axiosThis.panApi is defined
                                        const splitResult = axiosThis.panFile.split('/ap-backend/');
                                        //console.log("splitResult", splitResult);
                                        if (splitResult.length > 1) {
                                            // Check if the split operation was successful
                                            axiosThis.panApi = `${api.api_url}/${splitResult[1]}`;
                                        } else {
                                            // Handle the case where the split operation failed
                                            //console.error("Split operation failed.");
                                        }
                                    } else {
                                        // Handle the case where axiosThis.panApi is undefined
                                        //console.error("axiosThis.panApi is undefined.");
                                    }
                                    // axiosThis.panApi = `${api.api_url}/${axiosThis.panFile.split('/ap-backend/')[1]}`
                                    sessionStorage.setItem('panApi', axiosThis.panApi);

                                    ////console.log("SPL", axiosThis.panApi);

                                    axiosThis.digioFile = response.data[0].fileid
                                    axiosThis.digioMail = response.data[0].email
                                    localStorage.setItem('fileid', axiosThis.digioFile)
                                    localStorage.setItem('mailid', axiosThis.digioMail)
                                    ////console.log(response['fileid'], response['mailid'])
                                    axiosThis.cardloader = false;
                                    // axiosThis.step = 7;
                                    localStorage.setItem("stageis", 7)

                                    axiosThis.$router.push("/preview")

                                    // axios.Slidervalue = 84
                                })
                                    .catch(function () {
                                        axiosThis.cardloader = false;
                                        ////console.log('FAILURE!!');
                                        axiosThis.toasttext = 'Dont repeat the proof in document upload'
                                        axiosThis.snackbar = true
                                        axiosThis.success = false;
                                        axiosThis.failure = true;
                                        // axiosThis.$v.$reset()
                                    });


                            } else if ((axiosThis.acctype == "RA") && (axiosThis.pennyVerify == 'No') && (axiosThis.panStatus == false)) {
                                sessionStorage.setItem('acctype', axiosThis.acctype);
                                sessionStorage.setItem('pennyVerify', axiosThis.pennyVerify);
                                sessionStorage.setItem('panStatus', axiosThis.panStatus);

                                //console.log('>> formData RA NO F falseeeeeee>> ');
                                localStorage.setItem("stageis", 6)

                                axiosThis.$router.push("/fileupload")

                                // axiosThis.step = 6
                                // axiosThis.Slidervalue = 72
                                axiosThis.eduFile = false
                            } else if ((axiosThis.acctype == "RA") && (axiosThis.pennyVerify == 'yes') && (axiosThis.panStatus == false)) {
                                //console.log('>> formData RA YES F falseeeeeee>> ');
                                localStorage.setItem("stageis", 6)

                                axiosThis.$router.push("/fileupload")
                                sessionStorage.setItem('acctype', axiosThis.acctype);
                                sessionStorage.setItem('pennyVerify', axiosThis.pennyVerify);
                                sessionStorage.setItem('panStatus', axiosThis.panStatus);
                                // axiosThis.step = 6
                                // axiosThis.Slidervalue = 72
                                axiosThis.eduFile = false
                            } else if ((axiosThis.acctype == "RA") && (axiosThis.pennyVerify == 'No') && (axiosThis.panStatus == true)) {
                                //console.log('>> formData RA NO T falseeeeeee>> ');
                                localStorage.setItem("stageis", 6)

                                axiosThis.$router.push("/fileupload")
                                sessionStorage.setItem('acctype', axiosThis.acctype);
                                sessionStorage.setItem('pennyVerify', axiosThis.pennyVerify);
                                sessionStorage.setItem('panStatus', axiosThis.panStatus);
                                // axiosThis.step = 6
                                // axiosThis.Slidervalue = 72
                                axiosThis.eduFile = false
                            } else if (axiosThis.acctype == "AP") {
                                localStorage.setItem("stageis", 6)

                                axiosThis.$router.push("/fileupload")
                                sessionStorage.setItem('acctype', axiosThis.acctype);
                                sessionStorage.setItem('pennyVerify', axiosThis.pennyVerify);
                                sessionStorage.setItem('panStatus', axiosThis.panStatus);
                                // axiosThis.step = 6
                                // axiosThis.Slidervalue = 72
                                axiosThis.eduFile = true
                            }

                            // else if(axiosThis.acctype == "AP" && axiosThis.pennyVerify == 'yes' &&  axiosThis.panStatus == true){
                            //   axiosThis.eduFile = true
                            //   axiosThis.step = 6
                            //   axiosThis.Slidervalue = 72
                            // }

                            // else{
                            //   axiosThis.step = 6
                            //   axiosThis.Slidervalue = 72
                            // }





                            //  axiosThis.step=6


                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    // alert("requiredment is missing  ")
                    this.toasttext = 'Some Requirement are missing'
                    this.success = false
                    this.failure = true
                    this.snackbar = true
                    // this.success
                }
            }
            else if (this.mobile_num && this.exchangeSelect.length != 0 && this.tradingExperience <= 50 && this.tradingExperience != '' && this.qualification != null && this.occupation != null) {
                //   this.$v.$reset()
                //console.log("ENTER IN API")

                var config1 = {
                    method: 'post',
                    url: api.api_url + '/segmentUpdation?mob=' + this.mobile_num + '&seg=' + this.exchangeSelect + '&tradeExp=' + this.tradingExperience + '&qualification=' + this.qualification + '&occupation=' + this.occupation + '&change=' + 'update',
                    headers: {}
                };
                //console.log(this.exchangeSelect, "KJKJKJ");

                const axiosThis = this
                axios(config1)
                    .then(function (response) {
                        ////console.log(JSON.stringify(response.data));

                        ////console.log("ewq", response.data);
                        axiosThis.acctype = response.data['RAAP']
                        sessionStorage.setItem('acctype', axiosThis.acctype)
                        axiosThis.pennyVerify = response.data.pennyDropVerify
                        sessionStorage.setItem('pennyVerify', axiosThis.pennyVerify)
                        axiosThis.panStatus = response.data.pan
                        sessionStorage.setItem('panStatus', axiosThis.panStatus)


                        ////console.log("type121", axiosThis.panStatus);
                        ////console.log("type121", axiosThis.acctype);
                        ////console.log("type121", axiosThis.pennyVerify);

                        // if (axiosThis.acctype == "RA") {
                        //   axiosThis.eduFile = false
                        // axiosThis.step =

                        // } else {
                        // axiosThis.step = 6

                        // }
                        if ((axiosThis.acctype == "RA") && (axiosThis.pennyVerify == 'yes') && (axiosThis.panStatus == true)) {
                            let formData = new FormData();

                            formData = ''


                            //console.log('>> formData falseeeeeee>> ');

                            // You should have a server side REST API 
                            axios.post(api.api_url + '/fileUpload?mob=' + atob(localStorage.getItem('hash')),
                                formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }
                            ).then(function (response) {
                                ////console.log('SUCCESS!!');
                                axiosThis.DisplayData = response.data
                                // axiosThis.$router.push('/preview') 
                                sessionStorage.setItem('DisplayData', JSON.stringify(axiosThis.DisplayData));
                                // this.$router.push("/");

                                // axios.Slidervalue = 84

                                // aadhae
                                axiosThis.aadharFile = axiosThis.DisplayData[0].adharimg
                                ////console.log("AL", axiosThis.aadharFile)
                                // axiosThis.aadhaApi = axiosThis.aadharFile.split('/').slice(-5).join('/');
                                axiosThis.aadhaApi = `${api.api_url}/${axiosThis.aadharFile.split('/ap-backend/')[1]}`
                                sessionStorage.setItem('aadhaApi', axiosThis.aadhaApi);

                                ////console.log("SAL", axiosThis.aadhaApi);
                                // pan
                                axiosThis.panFile = axiosThis.DisplayData[0].panimgloc
                                ////console.log("PL", axiosThis.panFile)
                                ////axiosThis.panApi = axiosThis.panFile.split('/').slice(-5).join('/');
                                // axiosThis.panApi = axiosThis.panFile.split('/').slice(-5).join('/');
                                axiosThis.panApi = `${api.api_url}/${axiosThis.panFile.split('/ap-backend/')[1]}`
                                sessionStorage.setItem('panApi', axiosThis.panApi);

                                ////console.log("SPL", axiosThis.panApi);

                                axiosThis.digioFile = response.data[0].fileid
                                axiosThis.digioMail = response.data[0].email
                                localStorage.setItem('fileid', axiosThis.digioFile)
                                localStorage.setItem('mailid', axiosThis.digioMail)
                                ////console.log(response['fileid'], response['mailid'])
                                axiosThis.cardloader = false;
                                // axiosThis.step = 7;
                                localStorage.setItem("stageis", 7)

                                axiosThis.$router.push("/preview")

                                // axios.Slidervalue = 84
                            })
                                .catch(function (error) {
                                    axiosThis.cardloader = false;
                                    ////console.log('FAILURE!!');
                                    console.log(error);
                                    axiosThis.toasttext = 'Error occur please try again'
                                    axiosThis.snackbar = true
                                    axiosThis.success = false;
                                    axiosThis.failure = true;
                                    // axiosThis.$v.$reset()
                                });


                        } else if ((axiosThis.acctype == "RA") && (axiosThis.pennyVerify == 'No') && (axiosThis.panStatus == false)) {
                            sessionStorage.setItem('acctype', axiosThis.acctype);
                            sessionStorage.setItem('pennyVerify', axiosThis.pennyVerify);
                            sessionStorage.setItem('panStatus', axiosThis.panStatus);

                            //console.log('>> formData RA NO F falseeeeeee>> ');
                            localStorage.setItem("stageis", 6)

                            axiosThis.$router.push("/fileupload")

                            // axiosThis.step = 6
                            // axiosThis.Slidervalue = 72
                            axiosThis.eduFile = false
                        } else if ((axiosThis.acctype == "RA") && (axiosThis.pennyVerify == 'yes') && (axiosThis.panStatus == false)) {
                            //console.log('>> formData RA YES F falseeeeeee>> ');
                            localStorage.setItem("stageis", 6)

                            axiosThis.$router.push("/fileupload")
                            sessionStorage.setItem('acctype', axiosThis.acctype);
                            sessionStorage.setItem('pennyVerify', axiosThis.pennyVerify);
                            sessionStorage.setItem('panStatus', axiosThis.panStatus);
                            // axiosThis.step = 6
                            // axiosThis.Slidervalue = 72
                            axiosThis.eduFile = false
                        } else if ((axiosThis.acctype == "RA") && (axiosThis.pennyVerify == 'No') && (axiosThis.panStatus == true)) {
                            //console.log('>> formData RA NO T falseeeeeee>> ');
                            localStorage.setItem("stageis", 6)

                            axiosThis.$router.push("/fileupload")
                            sessionStorage.setItem('acctype', axiosThis.acctype);
                            sessionStorage.setItem('pennyVerify', axiosThis.pennyVerify);
                            sessionStorage.setItem('panStatus', axiosThis.panStatus);
                            // axiosThis.step = 6
                            // axiosThis.Slidervalue = 72
                            axiosThis.eduFile = false
                        } else if (axiosThis.acctype == "AP") {
                            localStorage.setItem("stageis", 6)

                            axiosThis.$router.push("/fileupload")
                            sessionStorage.setItem('acctype', axiosThis.acctype);
                            sessionStorage.setItem('pennyVerify', axiosThis.pennyVerify);
                            sessionStorage.setItem('panStatus', axiosThis.panStatus);
                            // axiosThis.step = 6
                            // axiosThis.Slidervalue = 72
                            axiosThis.eduFile = true
                        }

                        // else if(axiosThis.acctype == "AP" && axiosThis.pennyVerify == 'yes' &&  axiosThis.panStatus == true){
                        //   axiosThis.eduFile = true
                        //   axiosThis.step = 6
                        //   axiosThis.Slidervalue = 72
                        // }

                        // else{
                        //   axiosThis.step = 6
                        //   axiosThis.Slidervalue = 72
                        // }





                        //  axiosThis.step=6


                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                // alert("requiredment is missing  ")
                this.toasttext = 'Some Requirement are missing'
                this.success = false
                this.failure = true
                this.snackbar = true
                // this.success
            }
        },
        handleKeyUp(event) {

            if (this.mobile_num && this.exchangeSelect.length != 0 && this.tradingExperience <= 50 && this.tradingExperience != '' && this.qualification != null && this.occupation != null) {
                // if (this.otpdisp && !this.emailDisp  && !this.isShowing && !this.emailOtpDisp  ) {
                if (event.key === 'Enter') {
                    // alert("fghj")
                    this.segmentSelection();
                    ////console.log("verify mobile Enter pressed");

                    // this.otpVerify();
                    // this.verifyEmail();
                }

                // }
            }
        },

    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.handleKeyUp);
    },
    computed: {

        //  otpErrors() {
        //     // const errors = []
        //     ////console.log("OTP Valid : ", this.errorOTPDisp, this.otp)
        //     if (this.errorOTPDisp) {
        //         this.toasttext = 'OTP is invalid'
        //       // errors.push('Enter OTP is invalid')
        //       return errors
        //     }
        //     else {
        //       ////console.log(this.$v.otp.$dirty, this.$v.otp.required);
        //       if (!this.$v.otp.$dirty) return errors
        //       !this.$v.otp.required && errors.push('OTP is required')
        //       return errors
        //     }

        //   },
    },
    watch: {
        loader() {
            const l = this.loader
            this[l] = !this[l]

            setTimeout(() => (this[l] = false), 3000)

            this.loader = null
        },
    },

    mounted() {
        // this.loader = 'loading2'
        document.addEventListener('keyup', this.handleKeyUp);

        this.mobile_num = atob(localStorage.getItem('hash'))
        this.gender = localStorage.getItem('gender')
        // this.exchangeSelect = []
        //console.log("enter mounted")

        //   this.$v.$reset()
        var config = {
            method: 'post',
            url: api.api_url + '/segmentUpdation?mob=' + this.mobile_num + '&seg=' + this.exchangeSelect + '&tradeExp=' + this.tradingExperience + '&qualification=' + this.qualification + '&occupation=' + this.occupation,
            headers: {}
        };
        var axiosThis = this
        axios(config)
            .then(function (response) {
                ////console.log(JSON.stringify(response.data));

                ////console.log("ewq", response.data);
                axiosThis.occupation = response.data['occupation']
                axiosThis.qualification = response.data['qualification']
                axiosThis.tradingExperience = response.data['tradeExpYear']
                //console.log("ewq", axiosThis.exchangeSelect);

                if (response.data['MCXseg'] != '' ) {
                    //console.log("enter push")
                    axiosThis.exchangeSelect.push(response.data['MCXseg'])
                    // axiosThis.exchangeSelect.push(response.data['NSEseg'])
                    // axiosThis.exchangeSelect.push(response.data['BSEseg'])
                }
                

                if (response.data['NSEseg'] != '' ) {
                    let text = response.data['NSEseg']
                    let trueorfalse = text.search("NSE_CASH");
                    if (trueorfalse != -1) {
                        axiosThis.exchangeSelect.push("NSE_CASH")
                    }
                    let text2 = response.data['NSEseg']
                    let trueorfalse2 = text2.search("NSE_FNO");
                    if (trueorfalse2 != -1) {
                        axiosThis.exchangeSelect.push("NSE_FNO")
                    }
                    //console.log("enter push")
                    // axiosThis.exchangeSelect.push(response.data['MCXseg'])
                    
                    // axiosThis.exchangeSelect.push(response.data['BSEseg'])
                }
                if ( response.data['BSEseg'] != '') {
                    //console.log("enter push")
                    let text = response.data['BSEseg']
                    // console.log(text.search("BSEwdwdwdwdwd_FNO"));
                    
                    let trueorfalse = text.search("BSE_CASH");
                    if (trueorfalse != -1) {
                        axiosThis.exchangeSelect.push("BSE_CASH")
                    }
                    let text2 = response.data['BSEseg']
                    let trueorfalse2 = text2.search("BSE_FNO");
                    if (trueorfalse2 != -1) {
                        axiosThis.exchangeSelect.push("BSE_FNO")
                    }
                    // axiosThis.exchangeSelect.push(response.data['MCXseg'])
                    // axiosThis.exchangeSelect.push(response.data['NSEseg'])
                    
                }
                //console.log("ewq", axiosThis.exchangeSelect);

                axiosThis.acctype = response.data['RAAP']
                sessionStorage.setItem('acctype', axiosThis.acctype)
                axiosThis.pennyVerify = response.data.pennyDropVerify
                sessionStorage.setItem('pennyVerify', axiosThis.pennyVerify)
                axiosThis.panStatus = response.data.pan
                sessionStorage.setItem('panStatus', axiosThis.panStatus)


                ////console.log("type121", axiosThis.panStatus);
                ////console.log("type121", axiosThis.acctype);
                ////console.log("type121", axiosThis.pennyVerify);

                // if (axiosThis.acctype == "RA") {
                //   axiosThis.eduFile = false
                // axiosThis.step = 7

                // } else {
                // axiosThis.step = 6

                // }
                // MOUNTED
                //         if ((axiosThis.acctype == "RA") && (axiosThis.pennyVerify == 'yes') && (axiosThis.panStatus == true)) {
                //             let formData = new FormData();

                //             formData = ''


                //             //console.log('>> formData falseeeeeee>> ');

                //             // You should have a server side REST API 
                //             axios.post(api.api_url + '/fileUpload?mob=' + atob(localStorage.getItem('hash')),
                //                 formData, {
                //                 headers: {
                //                     'Content-Type': 'multipart/form-data'
                //                 }
                //             }
                //             ).then(function (response) {
                //                 ////console.log('SUCCESS!!');
                //                 axiosThis.DisplayData = response.data
                //         // axiosThis.$router.push('/preview') 
                //                 sessionStorage.setItem('DisplayData', JSON.stringify(axiosThis.DisplayData));
                // // this.$router.push("/");

                //                 // axios.Slidervalue = 84

                //                 // aadhae
                //                 axiosThis.aadharFile = axiosThis.DisplayData[0].adharimg
                //                 ////console.log("AL", axiosThis.aadharFile)
                //                 axiosThis.aadhaApi = axiosThis.aadharFile.split('/').slice(-5).join('/');
                //                 axiosThis.aadhaApi = `${api.api_url}/${axiosThis.aadhaApi}`
                //                 sessionStorage.setItem('aadhaApi', axiosThis.aadhaApi);

                //                 ////console.log("SAL", axiosThis.aadhaApi);
                //                 // pan
                //                 axiosThis.panFile = axiosThis.DisplayData[0].panimgloc
                //                 ////console.log("PL", axiosThis.panFile)
                //                 ////axiosThis.panApi = axiosThis.panFile.split('/').slice(-5).join('/');
                //                 axiosThis.panApi = axiosThis.panFile.split('/').slice(-5).join('/');
                //                 axiosThis.panApi = `${api.api_url}/${axiosThis.panApi}`
                //                 sessionStorage.setItem('panApi', axiosThis.panApi);

                //                 ////console.log("SPL", axiosThis.panApi);

                //                 axiosThis.digioFile = response.data[0].fileid
                //                 axiosThis.digioMail = response.data[0].email
                //                 localStorage.setItem('fileid', axiosThis.digioFile)
                //                 localStorage.setItem('mailid', axiosThis.digioMail)
                //                 ////console.log(response['fileid'], response['mailid'])
                //                 axiosThis.cardloader = false;
                //                 // axiosThis.step = 7;
                //                 axiosThis.$router.push("/preview")

                //                 // axios.Slidervalue = 84
                //             })
                //                 .catch(function () {
                //                     axiosThis.cardloader = false;
                //                     ////console.log('FAILURE!!');
                //                     axiosThis.toasttext = 'Dont repeat the proof in document upload'
                //                     axiosThis.snackbar = true
                //                     axiosThis.success = false;
                //                     axiosThis.failure = true;
                //                     axiosThis.$v.$reset()
                //                 });


                //         } else if ((axiosThis.acctype == "RA") && (axiosThis.pennyVerify == 'No') && (axiosThis.panStatus == false)) {
                //             sessionStorage.setItem('acctype', axiosThis.acctype);
                //             sessionStorage.setItem('pennyVerify', axiosThis.pennyVerify);
                //             sessionStorage.setItem('panStatus', axiosThis.panStatus);

                //             //console.log('>> formData RA NO F falseeeeeee>> ');
                //             axiosThis.$router.push("/fileupload")

                //             // axiosThis.step = 6
                //             // axiosThis.Slidervalue = 72
                //             axiosThis.eduFile = false
                //         } else if ((axiosThis.acctype == "RA") && (axiosThis.pennyVerify == 'yes') && (axiosThis.panStatus == false)) {
                //             //console.log('>> formData RA YES F falseeeeeee>> ');
                //             axiosThis.$router.push("/fileupload")
                //             sessionStorage.setItem('acctype', axiosThis.acctype);
                //             sessionStorage.setItem('pennyVerify', axiosThis.pennyVerify);
                //             sessionStorage.setItem('panStatus', axiosThis.panStatus);
                //             // axiosThis.step = 6
                //             // axiosThis.Slidervalue = 72
                //             axiosThis.eduFile = false
                //         } else if ((axiosThis.acctype == "RA") && (axiosThis.pennyVerify == 'No') && (axiosThis.panStatus == true)) {
                //             //console.log('>> formData RA NO T falseeeeeee>> ');
                //             axiosThis.$router.push("/fileupload")
                //             sessionStorage.setItem('acctype', axiosThis.acctype);
                //             sessionStorage.setItem('pennyVerify', axiosThis.pennyVerify);
                //             sessionStorage.setItem('panStatus', axiosThis.panStatus);
                //             // axiosThis.step = 6
                //             // axiosThis.Slidervalue = 72
                //             axiosThis.eduFile = false
                //         } else if (axiosThis.acctype == "AP") {
                //             axiosThis.$router.push("/fileupload")
                //             sessionStorage.setItem('acctype', axiosThis.acctype);
                //             sessionStorage.setItem('pennyVerify', axiosThis.pennyVerify);
                //             sessionStorage.setItem('panStatus', axiosThis.panStatus);
                //             // axiosThis.step = 6
                //             // axiosThis.Slidervalue = 72
                //             axiosThis.eduFile = true
                //         }

                // else if(axiosThis.acctype == "AP" && axiosThis.pennyVerify == 'yes' &&  axiosThis.panStatus == true){
                //   axiosThis.eduFile = true
                //   axiosThis.step = 6
                //   axiosThis.Slidervalue = 72
                // }

                // else{
                //   axiosThis.step = 6
                //   axiosThis.Slidervalue = 72
                // }





                //  axiosThis.step=6


            })
            .catch(function (error) {
                console.log(error);
            });
        //console.log(this.exchangeSelect, "jkjkjkj")
        this.mainurl = api.api_url;

    },
}

</script>
<style lang="scss">
.card-sty1 {
    /* border: 1px solid #F2F2F2 !important; */
    box-shadow: 0px 20px 25px 20px rgba(175, 175, 175, 0.2) !important;
    overflow: hidden;
    border-radius: 30px !important;
    /* margin-left: -1px !important; */
    /* margin-right: 2px !important; */
}

.body-card {
    height: 100vh !important;


    /* overflow-y: scroll !important; */
}

.v-application .blue--text {
    color: #000000 !important;
    caret-color: #ffffff !important;
}

// ::-webkit-scrollbar {
//     display: none;
// }

.custom-loader {
    animation: loader 1s infinite;
    display: flex;
}

@-moz-keyframes loader {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes loader {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@-o-keyframes loader {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes loader {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

.v-select__selection.v-select__selection--comma {
    font-size: 14px;
}

.input#input-20 {
    font-size: 12px !important;
}

.v-list-item__title {
    font-weight: 400px !important;
    font-size: 12px !important;
}
</style>