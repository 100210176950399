<template>
    <div>
        <v-snackbar v-if="isLesserThanResolution" v-model="snackbar" :timeout="timeout" absolute bottom color="success"
            outlined class="">
            <!-- <v-card> -->
            <v-icon color="green" v-if="success">
                "mdi mdi-check-circle
            </v-icon>
            <v-icon color="red" v-if="failure">
                mdi mdi-close-circle
            </v-icon>
            {{ toasttext }}

            <template v-slot:action="{ attrs }">
                <v-btn icon color="green" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi mdi-close</v-icon>
                </v-btn>
            </template>
            <!-- </v-card> -->
        </v-snackbar>
        <v-snackbar v-else v-model="snackbar" :timeout="timeout" absolute top color="success" outlined right class="">
            <!-- <v-card> -->
            <v-icon color="green" v-if="success">
                "mdi mdi-check-circle
            </v-icon>
            <v-icon color="red" v-if="failure">
                mdi mdi-close-circle
            </v-icon>
            {{ toasttext }}

            <template v-slot:action="{ attrs }">
                <v-btn icon color="green" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi mdi-close</v-icon>
                </v-btn>
            </template>
            <!-- </v-card> -->
        </v-snackbar>
        <v-container class="body-card">
            <v-card width="" height="" class="card-sty1  " style="">
                <v-progress-linear absolute color='#0037B7' height="12" value="74"></v-progress-linear>
                <v-toolbar color="transparent" dense flat class="mt-5 mb-3">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <img :src="require('@/assets/Zebu Logo.svg')" class="ml-1" contain width="90px">

                        </v-col>
                        <v-col cols="3" class="d-none d-lg-block">

                        </v-col>
                        <v-col class="stageselect" cols="3">
                            <v-select outlined style="" dense @change="stageToo()" item-text="val" item-value="key"
                                item-disabled="disable" :items="stagesitemsis" v-model="stageis" flat solo
                                background-color="#ffffff" class="txtfield elevation-0 rounded-pill mr-4 ml-auto mt-3 "
                                label="Stage" hide-details append-icon="mdi-chevron-down"></v-select>

                        </v-col>
                    </v-row>
                </v-toolbar>
                <v-row class="px-8">
                    <v-col class="d-none d-lg-block">
                        <v-card width="100%" class="rounded-0 d-none d-lg-block elevation-0">
                            <img v-for="(image, index) in imagess" :key="index" :src="image"
                                :style="{ display: index === currentIndex ? 'block' : 'none' }" style="height: 26em;
  display: flex;
  align-items: center;
  justify-content: center" width="100%" />
                        </v-card>
                    </v-col>
                    <v-col class="pl-2 cols-sm-12">
                        <v-card class="rounded-0 elevation-0 scrollhighlight" style="overflow: scroll; ">
                            <div class="px-0 "
                                style="padding-left:20px;padding-right:30px; overflow: inline-flex; overflow: hidden;">
                                <!-- <p class="body-1 font-weight-bold mb-5">Upload Your Document</p> -->
                                <p   class="title font-weight-bold mb-0">Upload Your Document</p>
                                <v-card v-if="cardloader" fill-height style="background-color: rgba(255, 255, 255, 0.733);"
                                    width="" class="rounded-0 elevation-0 loader ">
                                    <div class="" style="margin-top: 138px;">
                                        <v-progress-circular class="blocktext" :size="70" color="primary"
                                            indeterminate></v-progress-circular>
                                        <p class="blocktext text-center subtitle-1  font-weight-bold">Processing please be
                                            wait....</p>
                                    </div>

                                </v-card>
                                <!-- <h2 style="padding-left:8px;margin-top:-10px; ">Upload Your Document</h2> -->

                                <!-- <v-file-input chips counter show-size small-chips truncate-length="21" label="Aadhar" v-model="aadharFile"
                            :error-messages="adharErrors" @input="$v.aadharFile.$touch()" @blur="$v.aadharFile.$touch()"
                            accept="image/png, image/jpg, image/jpeg" required></v-file-input> -->
                                <!-- <v-file-input v-if="!panStatus" hide-spin-buttons dense outlined rounded solo flat
                                    :rules="[v => !!v || 'Pan is required',]" prefix="      " counter show-size small-chips
                                    truncate-length="21" label="PAN" v-model="panFile"
                                    accept="image/png, image/jpg, image/jpeg" :error-messages="panfErrors"
                                    @input="$v.panFile.$touch()" @blur="$v.panFile.$touch()" required></v-file-input>
                                <v-file-input v-if="pennyVerify == 'No'" hide-spin-buttons dense outlined rounded solo flat
                                    :rules="[v => !!v || 'Bank is required',]" prefix="      " counter show-size small-chips
                                    truncate-length="21" label="Bank Proof" v-model="bankFile"
                                    accept="image/png, image/jpg, image/jpeg" :error-messages="bankfErrors"
                                    @input="$v.bankFile.$touch()" @blur="$v.bankFile.$touch()" required></v-file-input>
                                <v-file-input hide-spin-buttons dense outlined rounded solo flat
                                    :rules="[v => !!v || 'Education is required',]" prefix="      "
                                    v-if="acctype == 'AP' && pennyVerify == 'yes' && panStatus == true || acctype == 'AP' && pennyVerify == 'No' && panStatus == true || acctype == 'AP' && pennyVerify == 'yes' && panStatus == false || acctype == 'AP' && pennyVerify == 'No' && panStatus == false"
                                    chips counter show-size truncate-length="21" label="Education Proof"
                                    v-model="educationFiler" accept="image/png, image/jpg, image/jpeg"
                                    :error-messages="educationfErrors" @input="$v.educationFiler.$touch()"
                                    @blur="$v.educationFiler.$touch()" required></v-file-input> -->
                                <!-- after new design change -->




                                <div>
                                    <div>
                                        <div  >
                                            <!-- <p class="body-2 font-weight-medium mb-3"></p> -->
                                            <p class="   mt-1 " style="font-size: 15px; color: #000000 !important;">
                                                Education Proof</p>
                                            <div class="mx-4 mb-5">

                                                <!-- <p class="caption font-weight-medium mb-0"></p> -->
                                                <p class="   mt-1 " style="font-size: 13px; color: #808080 !important;">
                                                    Provide Your Education Proof <span v-if="edufileprev" @click="openFilePicker" style="cursor: pointer;color:#0037B7" class="caption ">- (Change)</span></p>

                                                <div v-if="!edufileprev" class="cards" @click="openFilePicker">
                                                    <p class="caption font-weight-bold grey--text">Click here</p>

                                                </div>
                                                <input ref="fileUpload" accept="image/png, image/jpg, image/jpeg"
                                                    type="file" @change="handleFileChange" style="display: none" />
                                                <div>
                                                    <!-- <v-btn v-if="edufileprev" icon :ripple="false" @click="eraseedu()"
                                                        style="position: absolute;right: 53px;"><v-icon>mdi
                                                            mdi-close-circle-outline</v-icon></v-btn> -->
                                                    <div id="image-holder">

                                                        <img v-for="(image, index) in images" :src="image" :key="index"
                                                            class="cards" id="thumb-image" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div   class="mb-7">
                                            <!-- <p class="body-2 mt-1 font-weight-medium">Pan Proof</p> -->
                                            <p class="   mt-1 " style="font-size: 15px; color: #000000 !important;">Pan
                                                Proof</p>

                                            <div class="mx-4">
                                                <p class="   mt-1 " style="font-size: 13px; color: #808080 !important;">
                                                    Provide Your Pan Proof <span v-if="panfileprev" @click="openFilePicker1" style="cursor: pointer;color:#0037B7" class="caption ">- (Change)</span></p>
                                                <div v-if="!panfileprev" class="cards" @click="openFilePicker1">
                                                    <p class="caption font-weight-bold grey--text">Click here</p>
                                                </div>
                                                <input ref="fileUpload1" accept="image/png, image/jpg, image/jpeg"
                                                    type="file" @change="handleFileChange1" style="display: none" />
                                                <div id="image-holder1">
                                                    <img v-for="(image1, index) in images1" :src="image1" :key="index"
                                                        class="cards" id="thumb-image1" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div  >
                                            <p class="   mt-1 " style="font-size: 15px; color: #000000 !important;">Bank
                                                Proof</p>

                                            <div class="mx-4">
                                                <p class="   mt-1 " style="font-size: 13px; color: #808080 !important;">
                                                    Provide Your Bank Proof  <span v-if="bankfileprev" @click="openFilePicker2" style="cursor: pointer;color:#0037B7" class="caption  ">- (Change)</span></p>
                                                   


                                                <div v-if="!bankfileprev" class="cards" @click="openFilePicker2">
                                                    <p class="caption font-weight-bold grey--text">Click here</p>
                                                </div>
                                                <input  ref="fileUpload2" accept="image/png, image/jpg, image/jpeg"
                                                    type="file" @change="handleFileChange2" style="display: none" />
                                                <div    id="image-holder2">
                                                    <img v-for="(image2, index) in images2" :src="image2" :key="index"
                                                        id="thumb-image2" class="cards" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <v-btn rounded color="black" @click="fileUpload()"
                                    class="otpbtn fltbtn white--text py-6 mt-6  mx-2"
                                    style="width: 94%; position: absolute; bottom">Continue</v-btn>
                            </div>

                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
    </div>
</template>
<script>
import axios from 'axios';
import api from '@/api';
import imagePath  from '../assets/inv1.svg'
import imagePath1  from '../assets/inv2.svg'
import imagePath2  from '../assets/inv3.svg'
import imagePath3  from '../assets/inv4.svg'
import imagePath4  from '../assets/inv5.svg'
export default {
    data: () => ({
        // filepreview
        images: [],
        img: '',
        images1: [],
        img1: '',
        images2: [],
        img2: '',
        edufileprev: false,
        panfileprev: false,
        bankfileprev: false,
        // Sign Up
        mobile_num: null,
        otp: '',
        stageis: 6,
        stagesitemsis: [{ key: 1, val: 'Sign up' }, { key: 2, val: 'Identity verification' }, { key: 3, val: 'Digilocker' }, { key: 4, val: 'Bank details' }, { key: 5, val: "Segment selection" }, { key: 6, val: 'Document upload' }, { key: 7, val: 'e-sign' },],

        otpdisp: true,
        snackbar: false,
        timerEnabled: false,
        timerCount: 120,
        resendText: false,
        toasttext: 'null',
        cardloader: false,
        errorOTPDisp: false,
        numberStart: true,
        otpstart: true,
        otptext: '',
        // test\
        mainurl: null,

        accountTypeItems: ['Saving Account', 'Current Account'],
        accountType: null,
        accNo: null,
        ifsc: null,
        retrybank: false,

        retrybankacc: false,
        retryCount: 0,
        fileattach1st: false,
        opencam: false,
        // cam
        isCameraOpen: false,
        isPhotoTaken: false,
        isShotPhoto: false,
        isLoading: false,
        link: '#',
        photoData: null,
        localHash: null,
        dialog1: false,
        panStatus: false,
        pennyVerify: '',
        acctype: null,
        eduFile: false,
        DisplayData: null,
        aadhaApi: '',
        fatherNameval: '',
        corFather: '',
        bankApi: '',
        Slidervalue: 1,
        eduApi: '',
        // fatherNameval : '',
        // from kyc
        loader: null,
        panLoader: null,
        loading: false,
        // mobile_num: null,
        //errorMobNumber:false,
        logstatus: false,
        reference: null,
        // timerCount: 120,
        // timerEnabled: false,
        // resendText: false,
        email: null,
        emailDisp: false,
        emailOtpDisp: false,
        otpemail: null,
        // otp: null,
        checkbox: false,
        invalid: null,
        text: `You will receive an OTP on your number`,
        loadertext: "Verify",
        timeout: 1000,
        // otpdisp: true,
        mobdisp: true,
        isShowing: false,
        activePicker: null,
        date: null,
        menu: false,
        panDetaildisp: true,
        panFetchDetail: false,
        panInput: null,
        // accNo:s null,
        // ifsc: null,
        bank_name: null,
        bank_branch: null,
        bank_micr: null,
        enabledRegisteredAP: false,
        regMemberName: null,
        exchangeSelect: [],
        tradingExperience: null,
        qualificationList: ['Highschool', 'Graduation', 'Post Graduation', 'Doctrate', 'Prof. Degree', 'Under High School', 'Illiterate-Other'],
        occupationList: ['Private Sector', 'Public Sector', 'Government Service', 'Business', 'Professional Agriculturist', 'Retired', 'Housewife', 'Student', 'Forex Dealer', 'Other'],
        qualification: null,
        occupation: null,
        errorDescPAN: false,
        KycFetchDisp: false,
        dob: null,
        aadharNo: null,
        add1: null,
        add2: null,
        add3: null,
        add4: null,
        address: null,
        country: null,
        district: null,
        state: null,
        pinCode: null,
        fatherName: null,
        gender: null,
        name: null,
        panFile: null,
        // aadharFile:null,
        bankFile: null,
        educationFiler: null,
        kycLoader: false,
        checkboxEsign: false,
        digioFile: null,
        digioMail: null,
        ekycStatus: false,
        step: 1,
        bankverifystatus: null,

        digiInformationShow: false,
        dialog: false,
        isDarkModeEnabled: false,
        // errorOTPDisp: false,
        erroreOTPDisp: false,
        // cardloader: false,
        ifscErrorDesc: false,
        // snackbar: false,
        // toasttext: 'null',
        // timeout: 2000,
        panInputLength: false,
        success: false,
        failure: false,
        imagess: [
      // 'http://zebu-ekcy.web.app/img/inv3.bf76f1c4.svg',
      // 'http://zebu-ekcy.web.app/img/inv5.fb7c3474.svg',
      // 'http://zebu-ekcy.web.app/img/trd3.2bc52f23.svg',
      // 'http://zebu-ekcy.web.app/img/inv4.6e5ffeda.svg',
      // 'http://zebu-ekcy.web.app/img/inv4.6e5ffeda.svg',
      imagePath,imagePath1,imagePath2,imagePath3,imagePath4
      // 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',

      // 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',

      // 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',

    ],

        currentIndex: 0,
    }),
    created() {
        this.startAutoChange();
        let stages = Number(localStorage.getItem("stageis"));
        if (typeof stages === 'number') {
            for (let s = 0; s < this.stagesitemsis.length; s++) {
                this.stagesitemsis[s]['disable'] = this.stagesitemsis[s].key > stages ? true : false;
                // //console.log(this.stagesitemsis[s]['disable'],"this.stagesitemsis[s]['disable']")
            }
            if (stages < this.stageis) {
                this.stageis = stages;
                this.stageToo();
            }
        }

        else {
            this.$router.push("/");
        }
        const thresholdResolution = 767;

        const screenWidth = window.innerWidth;

        if (screenWidth < thresholdResolution) {
            this.isLesserThanResolution = true;
        } else {
            this.isLesserThanResolution = false;

        }
    },
    methods: {
        stageToo() {
            if (this.stageis == 1) {
                this.$router.push("/");
            }
            else if (this.stageis == 2) {
                this.$router.push("/pan");
            }
            else if (this.stageis == 3) {
                this.$router.push("/aadhar");
            }
            else if (this.stageis == 4) {
                this.$router.push("/bank");
            }
            else if (this.stageis == 5) {
                this.$router.push("/segments");
            }
            else if (this.stageis == 6) {
                this.$router.push("/fileupload");
            }
            else if (this.stageis == 7) {
                this.$router.push("/preview");
            }
            // else if (this.stageis == 8) {
            //     this.$router.push("");
            // }
        },

        // preview

        openFilePicker() {
            this.$refs.fileUpload.click();
        },
        handleFileChange(event) {
            //alert("edu in")

            const files = event.target.files;
            const imageHolder = document.getElementById('image-holder');
            imageHolder.innerHTML = '';

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const reader = new FileReader();

                reader.onload = (e) => {
                    this.images.push(e.target.result);
                    sessionStorage.setItem("this.images", this.images)
                    // this.educationFiler = this.images[0]
                    // //console.log(this.educationFiler, "edu");
                    const man = e.target.result
                    //console.log(man, "man");
                    this.panFile = this.images1[0]
                    //console.log(this.panFile, "pan");
                    const van = man; // Replace with your base64 data
                    const img = van.split(",")[1];
                    // Convert base64 to binary
                    const binaryData = atob(img);
                    const arrayBuffer = new ArrayBuffer(binaryData.length);
                    const uint8Array = new Uint8Array(arrayBuffer);
                    for (let i = 0; i < binaryData.length; i++) {
                        uint8Array[i] = binaryData.charCodeAt(i);
                    }

                    // Create Blob and File objects
                    const blob = new Blob([uint8Array], { type: "edu/png" });
                    this.educationFiler = new File([blob], "edu.png", { lastModified: new Date().getTime() });
                    sessionStorage.srtItem
                    //console.log(this.educationFiler, "file"); // Output the file object

                };

                if (file.type.includes('image')) {
                    reader.readAsDataURL(file);
                }
            }
            //alert("edu")
            this.edufileprev = true
        },
        eraseedu() {
            this.images.pop();
            // this.img= ''
            this.edufileprev = false
            const imageHolder = document.getElementById('image-holder1');
            imageHolder.innerHTML = '';
        },
        openFilePicker1() {
            this.$refs.fileUpload1.click();
        },
        handleFileChange1(event) {
            const files = event.target.files;
            const imageHolder = document.getElementById('image-holder1');
            imageHolder.innerHTML = '';

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const reader = new FileReader();

                reader.onload = (e) => {
                    this.images1.push(e.target.result);
                    sessionStorage.setItem("this.images1", this.images1)

                    const man = e.target.result
                    //console.log(man, "man");
                    // this.panFile = this.images1[0]
                    // //console.log(this.panFile, "pan");
                    const van = man; // Replace with your base64 data
                    const img = van.split(",")[1];
                    // Convert base64 to binary
                    const binaryData = atob(img);
                    const arrayBuffer = new ArrayBuffer(binaryData.length);
                    const uint8Array = new Uint8Array(arrayBuffer);
                    for (let i = 0; i < binaryData.length; i++) {
                        uint8Array[i] = binaryData.charCodeAt(i);
                    }

                    // Create Blob and File objects
                    const blob = new Blob([uint8Array], { type: "pan/png" });
                    this.panFile = new File([blob], "pan.png", { lastModified: new Date().getTime() });

                    //console.log(file, "file"); // Output the file object
                };

                if (file.type.includes('image')) {
                    reader.readAsDataURL(file);
                }
            }
            //alert("pan")
            this.panfileprev = true
        },
        openFilePicker2() {
            this.$refs.fileUpload2.click();
        },
        handleFileChange2(event) {
            const files = event.target.files;
            const imageHolder = document.getElementById('image-holder2');
            imageHolder.innerHTML = '';

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const reader = new FileReader();

                reader.onload = (e) => {
                    this.images2.push(e.target.result);

                    // this.bankFile = this.images2[0]
                    // //console.log(this.bankFile, "bank");
                    const man = e.target.result

                    const van = man; // Replace with your base64 data
                    const img = van.split(",")[1];
                    // Convert base64 to binary
                    const binaryData = atob(img);
                    const arrayBuffer = new ArrayBuffer(binaryData.length);
                    const uint8Array = new Uint8Array(arrayBuffer);
                    for (let i = 0; i < binaryData.length; i++) {
                        uint8Array[i] = binaryData.charCodeAt(i);
                    }

                    // Create Blob and File objects
                    const blob = new Blob([uint8Array], { type: "bank/png" });
                    this.bankFile = new File([blob], "bank.png", { lastModified: new Date().getTime() });
                    sessionStorage.setItem("this.images2", this.images2)

                    //console.log(this.bankFile, "file"); // Output the file object
                };

                if (file.type.includes('image')) {
                    reader.readAsDataURL(file);
                }
            }
            //alert("bank")
            this.bankfileprev = true

        },
        fileUpload() {
            // //console.log("this.image",this.image);
            //alert("enter")
            //console.log("this.pennyVerify", typeof (this.pennyVerify), typeof (this.panStatus), typeof (this.acctype));
            //console.log("123321", this.educationFiler)

            if ((this.acctype == 'AP') && (this.panStatus == 'true') && (this.pennyVerify == 'yes')) {
                // //alert("edu,bank")
                //console.log("1stcondition");
                // if (this.educationFiler) {
                this.cardloader = true;
                if ((this.educationFiler)) {
                    // //this.$v.$reset()
                    //console.log("in to the 1stcondition");


                    let formData = new FormData();
                    // ////console.log(this.panFile, this.aadharFile, this.bankFile, this.educationFiler)

                    // formData.append('panFile', this.panFile); 
                    // formData.append('aadharFile', this.aadharFile);
                    // formData.append('bankFile', this.bankFile);
                    formData.append('educationFile', this.educationFiler);
                    formData.append('mob', atob(localStorage.getItem('hash')));

                    ////console.log('>> formData >> ', formData);
                    var axiosThis = this
                    // You should have a server side REST API 
                    axios.post(api.api_url + '/fileUpload?mob=' + atob(localStorage.getItem('hash')),
                        formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    ).then(function (response) {
                        //console.log('SUCCESS!!');
                        axiosThis.cardloader = false

                        axiosThis.DisplayData = response.data
                        //console.log('SUCCESS!!', axiosThis.DisplayData);
                        // sessionStorage.setItem("DisplayData",axiosThis.DisplayData)
                        sessionStorage.setItem('DisplayData', JSON.stringify(axiosThis.DisplayData));
                        // aadhae
                        axiosThis.aadharFile = axiosThis.DisplayData[0].adharimg
                        //console.log("AL", axiosThis.aadharFile)
                        // axiosThis.aadhaApi = axiosThis.aadharFile.split('/').slice(-5).join('/');
                        // axiosThis.aadhaApi = axiosThis.aadharFile.split('/').slice(-4).join('/');/
                        // //console.log("aadharFile RA TRUE",axiosThis.aadharFile.split('/static'));
                        if (axiosThis.aadharFile) {
                            // Check if axiosThis.aadharFile is defined
                            const splitResult = axiosThis.aadharFile.split('/ap-backend/');
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.aadhaApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.aadharFile is undefined
                            //console.error("axiosThis.aadharFile is undefined.");
                        }






                        // axiosThis.aadhaApi = `${api.api_url}${axiosThis.aadharFile.split('/ap-backend/')[1]}`
                        //console.log("aadhaApi", axiosThis.aadhaApi);
                        sessionStorage.setItem('aadhaApi', axiosThis.aadhaApi);


                        ////console.log("SAL", axiosThis.aadhaApi);
                        // pan
                        axiosThis.panFile = axiosThis.DisplayData[0].panimgloc
                        ////console.log("PL", axiosThis.panFile)
                        //axiosThis.panApi = axiosThis.panFile.split('/').slice(-5).join('/');
                        // axiosThis.panApi = axiosThis.panFile.split('/').slice(-5).join('/');
                        // axiosThis.panApi = axiosThis.panFile.split('/').slice(-4).join('/');
                        // axiosThis.panApi = `${api.api_url}${axiosThis.panApi.split('/ap-backend/')[1]}`
                        if (axiosThis.panFile) {
                            // Check if axiosThis.panApi is defined
                            const splitResult = axiosThis.panFile.split('/ap-backend/');
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.panApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.panApi is undefined
                            //console.error("axiosThis.panApi is undefined.");
                        }

                        sessionStorage.setItem('panApi', axiosThis.panApi);


                        ////console.log("SPL", axiosThis.panApi);
                        // bank
                        // axiosThis.BankFile = axiosThis.DisplayData[0].bankFileLoc
                        // ////console.log("BL", axiosThis.BankFile)
                        // axiosThis.bankApi = axiosThis.BankFile.split('/').slice(-5).join('/');
                        // axiosThis.bankApi = `${api.api_url}${axiosThis.bankApi}`
                        // sessionStorage.setItem('bankApi', axiosThis.bankApi);
                        // ////console.log("SBL", axiosThis.bankApi);
                        // edu
                        // if (axiosThis.acctype == 'AP') {
                        axiosThis.eduFile = axiosThis.DisplayData[0].educationFileLoc
                        ////console.log("EL", axiosThis.eduFile)
                        //axiosThis.eduApi = axiosThis.eduFile.split('/').slice(-5).join('/');
                        // axiosThis.eduApi = axiosThis.eduFile.split('/').slice(-5).join('/');
                        // axiosThis.eduApi = axiosThis.eduFile.split('/').slice(-4).join('/');
                        // axiosThis.eduApi = `${api.api_url}${axiosThis.eduFile.split('/ap-backend/')[1]}`
                        if (axiosThis.eduFile) {
                            // Check if axiosThis.panApi is defined
                            const splitResult = axiosThis.eduFile.split('/ap-backend/');
                            //console.log("splitResult", splitResult);
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.eduApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.panApi is undefined
                            //console.error("axiosThis.panApi is undefined.");
                        }

                        sessionStorage.setItem('eduApi', axiosThis.eduApi);
                        ////console.log("SEL", axiosThis.eduApi);
                        // }


                        // 
                        axiosThis.digioFile = response.data[0].fileid
                        axiosThis.digioMail = response.data[0].email
                        localStorage.setItem('fileid', axiosThis.digioFile)
                        localStorage.setItem('mailid', axiosThis.digioMail)
                        //console.log(response['fileid'], response['mailid'])
                        //console.log("axios", axiosThis.digioFile, axiosThis.digioMail)
                        // axiosThis.cardloader = false;
                        //console.log('Come near push');


                        axiosThis.$router.push('/preview')
                        localStorage.setItem("stageis", 7)

                        // axiosThis.step = 7;
                        // axios.Slidervalue = 84
                    })
                        .catch((error) => {
                            console.log(error);
                        });
                }
                else {
                    this.cardloader = false;
                    this.toasttext = 'Please Upload the Image '
                    this.success = false;
                    this.failure = true;
                    this.snackbar = true

                    //this.$v.$reset()

                    // }
                }
                // }


            } else if ((this.acctype == 'AP') && (this.panStatus == 'false') && (this.pennyVerify == 'No')) {
                // //alert("edu,bank,pan")
                //console.log("2ndcondition");
                if (this.bankFile && this.educationFiler && this.panFile) {
                    this.cardloader = true;

                    //this.$v.$reset()
                    let formData = new FormData();
                    // ////console.log(this.panFile, this.aadharFile, this.bankFile, this.educationFiler)

                    formData.append('panFile', this.panFile);
                    // formData.append('aadharFile', this.aadharFile);
                    formData.append('bankFile', this.bankFile);
                    formData.append('educationFile', this.educationFiler);
                    formData.append('mob', atob(localStorage.getItem('hash')));

                    ////console.log('>> formData >> ', formData);
                    const axiosThis = this

                    // You should have a server side REST API 
                    axios.post(api.api_url + '/fileUpload?mob=' + atob(localStorage.getItem('hash')),
                        formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    ).then(function (response) {
                        ////console.log('SUCCESS!!');
                        ////console.log("123321", response)
                        // const axiosThis = this
                        axiosThis.cardloader = false

                        axiosThis.DisplayData = response.data
                        sessionStorage.setItem('DisplayData', JSON.stringify(axiosThis.DisplayData));

                        // aadhae
                        axiosThis.aadharFile = axiosThis.DisplayData[0].adharimg
                        ////console.log("AL", axiosThis.aadharFile)
                        // axiosThis.aadhaApi = axiosThis.aadharFile.split('/').slice(-5).join('/');
                        // axiosThis.aadhaApi = axiosThis.aadharFile.split('/').slice(-4).join('/');
                        // axiosThis.aadhaApi = `${api.api_url}${axiosThis.aadharFile.split('/ap-backend/')[1]}`
                        if (axiosThis.aadharFile) {
                            // Check if axiosThis.panApi is defined
                            const splitResult = axiosThis.aadharFile.split('/ap-backend/');
                            //console.log("splitResult", splitResult);
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.aadhaApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.panApi is undefined
                            //console.error("axiosThis.panApi is undefined.");
                        }
                        sessionStorage.setItem('aadhaApi', axiosThis.aadhaApi);
                        ////console.log("SAL", axiosThis.aadhaApi);
                        // pan
                        axiosThis.panFile = axiosThis.DisplayData[0].panimgloc
                        ////console.log("PL", axiosThis.panFile)
                        //axiosThis.panApi = axiosThis.panFile.split('/').slice(-5).join('/');
                        // axiosThis.panApi = axiosThis.panFile.split('/').slice(-4).join('/');
                        // axiosThis.panApi = axiosThis.panFile.split('/').slice(-5).join('/');
                        // axiosThis.panApi = `${api.api_url}${axiosThis.panFile.split('/ap-backend/')[1]}`
                        if (axiosThis.panFile) {
                            // Check if axiosThis.panApi is defined
                            const splitResult = axiosThis.panFile.split('/ap-backend/');
                            //console.log("splitResult", splitResult);
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.panApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.panApi is undefined
                            //console.error("axiosThis.panApi is undefined.");
                        }
                        sessionStorage.setItem('panApi', axiosThis.panApi);
                        ////console.log("SPL", axiosThis.panApi);
                        // bank
                        axiosThis.BankFile = axiosThis.DisplayData[0].bankFileLoc
                        ////console.log("BL", axiosThis.BankFile)
                        // axiosThis.bankApi = axiosThis.BankFile.split('/').slice(-4).join('/');
                        // axiosThis.bankApi = axiosThis.BankFile.split('/').slice(-5).join('/');
                        // axiosThis.bankApi = `${api.api_url}${axiosThis.BankFile.split('/ap-backend/')[1]}`
                        if (axiosThis.BankFile) {
                            // Check if axiosThis.panApi is defined
                            const splitResult = axiosThis.BankFile.split('/ap-backend/');
                            //console.log("splitResult", splitResult);
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.bankApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.panApi is undefined
                            //console.error("axiosThis.panApi is undefined.");
                        }
                        sessionStorage.setItem('bankApi', axiosThis.bankApi);
                        ////console.log("SBL", axiosThis.bankApi);
                        // edu
                        // if (axiosThis.type == 'AP') {
                        axiosThis.eduFile = axiosThis.DisplayData[0].educationFileLoc
                        ////console.log("EL", axiosThis.eduFile)
                        //axiosThis.eduApi = axiosThis.eduFile.split('/').slice(-5).join('/');
                        // axiosThis.eduApi = axiosThis.eduFile.split('/').slice(-4).join('/');
                        // axiosThis.eduApi = axiosThis.eduFile.split('/').slice(-5).join('/');
                        // axiosThis.eduApi = `${api.api_url}${axiosThis.eduFile.split('/ap-backend/')[1]}`
                        if (axiosThis.eduFile) {
                            // Check if axiosThis.panApi is defined
                            const splitResult = axiosThis.eduFile.split('/ap-backend/');
                            //console.log("splitResult", splitResult);
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.eduApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.panApi is undefined
                            //console.error("axiosThis.panApi is undefined.");
                        }
                        sessionStorage.setItem('eduApi', axiosThis.eduApi);

                        ////console.log("SEL", axiosThis.eduApi);
                        // }


                        // 
                        axiosThis.digioFile = response.data[0].fileid
                        axiosThis.digioMail = response.data[0].email
                        localStorage.setItem('fileid', axiosThis.digioFile)
                        localStorage.setItem('mailid', axiosThis.digioMail)

                        ////console.log(response['fileid'], response['mailid'])
                        axiosThis.cardloader = false;
                        // axiosThis.step = 7;
                        // axios.Slidervalue = 84
                        axiosThis.$router.push('/preview')
                        localStorage.setItem("stageis", 7)

                    })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    this.cardloader = false;
                    this.toasttext = 'Please Upload all files in Image Format'
                    this.success = false;
                    this.failure = true;
                    this.snackbar = true

                    //this.$v.$reset()

                    // }
                }
            } else if ((this.acctype == 'AP') && (this.panStatus == 'false') && (this.pennyVerify == 'yes')) {
                // //alert("edu,bank")
                //console.log("3condition");
                if (this.educationFiler && this.panFile) {
                    this.cardloader = true;
                    //console.log("enter in to 3rd condition")

                    //this.$v.$reset()
                    // var axiosThis = this
                    let formData = new FormData();
                    // ////console.log(this.panFile, this.aadharFile, this.bankFile, this.educationFiler)

                    formData.append('panFile', this.panFile);
                    // formData.append('aadharFile', this.aadharFile);
                    // formData.append('bankFile', this.bankFile);
                    formData.append('educationFile', this.educationFiler);
                    formData.append('mob', atob(localStorage.getItem('hash')));
                    const axiosThis = this

                    ////console.log('>> formData >> ', formData);
                    // You should have a server side REST API 
                    axios.post(api.api_url + '/fileUpload?mob=' + atob(localStorage.getItem('hash')),
                        formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    ).then(function (response) {
                        ////console.log('SUCCESS!!');
                        // const axiosThis = this
                        axiosThis.cardloader = false

                        axiosThis.DisplayData = response.data
                        sessionStorage.setItem('DisplayData', JSON.stringify(axiosThis.DisplayData));

                        // aadhae
                        axiosThis.aadharFile = axiosThis.DisplayData[0].adharimg
                        ////console.log("AL", axiosThis.aadharFile)
                        // axiosThis.aadhaApi = axiosThis.aadharFile.split('/').slice(-5).join('/');
                        // axiosThis.aadhaApi = axiosThis.aadharFile.split('/').slice(-4).join('/');
                        // axiosThis.aadhaApi = `${api.api_url}${axiosThis.aadharFile.split('/ap-backend/')[1]}`

                        sessionStorage.setItem('aadhaApi', axiosThis.aadhaApi);
                        ////console.log("SAL", axiosThis.aadhaApi);
                        // pan
                        if (axiosThis.aadharFile) {
                            // Check if axiosThis.panApi is defined
                            const splitResult = axiosThis.aadharFile.split('/ap-backend/');
                            //console.log("splitResult", splitResult);
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.aadhaApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.panApi is undefined
                            //console.error("axiosThis.panApi is undefined.");
                        }
                        axiosThis.panFile = axiosThis.DisplayData[0].panimgloc
                        ////console.log("PL", axiosThis.panFile)
                        //axiosThis.panApi = axiosThis.panFile.split('/').slice(-5).join('/');
                        // axiosThis.panApi = axiosThis.panFile.split('/').slice(-5).join('/');
                        // axiosThis.panApi = axiosThis.panFile.split('/').slice(-4).join('/');
                        // axiosThis.panApi = `${api.api_url}${axiosThis.panFile.split('/ap-backend/')[1]}`
                        if (axiosThis.panFile) {
                            // Check if axiosThis.panApi is defined
                            const splitResult = axiosThis.panFile.split('/ap-backend/');
                            //console.log("splitResult", splitResult);
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.panApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.panApi is undefined
                            //console.error("axiosThis.panApi is undefined.");
                        }

                        sessionStorage.setItem('panApi', axiosThis.panApi);
                        ////console.log("SPL", axiosThis.panApi);
                        // bank
                        // axiosThis.BankFile = axiosThis.DisplayData[0].bankFileLoc
                        // ////console.log("BL", axiosThis.BankFile)
                        // axiosThis.bankApi = axiosThis.BankFile.split('/').slice(-5).join('/');
                        // axiosThis.bankApi = `${api.api_url}${axiosThis.bankApi}`
                        sessionStorage.setItem('bankApi', axiosThis.bankApi);
                        // ////console.log("SBL", axiosThis.bankApi);
                        // edu
                        // if (axiosThis.type == 'AP') {
                        axiosThis.eduFile = axiosThis.DisplayData[0].educationFileLoc
                        ////console.log("EL", axiosThis.eduFile)
                        //axiosThis.eduApi = axiosThis.eduFile.split('/').slice(-5).join('/');
                        // axiosThis.eduApi = axiosThis.eduFile.split('/').slice(-4).join('/');
                        // axiosThis.eduApi = axiosThis.eduFile.split('/').slice(-5).join('/');
                        // axiosThis.eduApi = `${api.api_url}${axiosThis.eduFile.split('/ap-backend/')[1]}`

                        if (axiosThis.eduFile) {
                            // Check if axiosThis.panApi is defined
                            const splitResult = axiosThis.eduFile.split('/ap-backend/');
                            //console.log("splitResult", splitResult);
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.eduApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.panApi is undefined
                            //console.error("axiosThis.panApi is undefined.");
                        }
                        sessionStorage.setItem('eduApi', axiosThis.eduApi);
                        ////console.log("SEL", axiosThis.eduApi);
                        // }


                        // 
                        axiosThis.digioFile = response.data[0].fileid
                        axiosThis.digioMail = response.data[0].email
                        localStorage.setItem('fileid', axiosThis.digioFile)
                        localStorage.setItem('mailid', axiosThis.digioMail)
                        ////console.log(response['fileid'], response['mailid'])
                        axiosThis.cardloader = false;
                        // sessionStorage.setItem('DisplayData', JSON.stringify(axiosThis.DisplayData));
                        axiosThis.$router.push('/preview')
                        localStorage.setItem("stageis", 7)
                        // axiosThis.step = 7;
                        // axios.Slidervalue = 84

                    })
                        .catch((error) => {
                            console.log(error);
                        });

                } else {
                    this.cardloader = false;
                    this.snackbar = true

                    this.toasttext = 'Please Upload the image'
                    this.success = false;
                    this.failure = true;

                }


            } else if ((this.acctype == 'AP') && (this.panStatus == 'true') && (this.pennyVerify == 'No')) {
                // //alert("edu,bank")
                //console.log("4condition", this.cardloader);


                this.cardloader = true;
                //console.log("condition", this.cardloader);

                //console.log("enter in to condition");
                //console.log("qqqqq", this.bankFile, this.educationFiler);
                if (this.educationFiler && this.bankFile) {
                    //this.$v.$reset()
                    // var axiosThis = this
                    //console.log("enter in to 2nd condition", this.bankFile, this.educationFiler);

                    let formData = new FormData();
                    // ////console.log(this.panFile, this.aadharFile, this.bankFile, this.educationFiler)

                    // formData.append('panFile', this.panFile); 
                    // formData.append('aadharFile', this.aadharFile);
                    formData.append('bankFile', this.bankFile);
                    formData.append('educationFile', this.educationFiler);
                    formData.append('mob', atob(localStorage.getItem('hash')));

                    ////console.log('>> formData >> ', formData);

                    // You should have a server side REST API 
                    const axiosThis = this

                    axios.post(api.api_url + '/fileUpload?mob=' + atob(localStorage.getItem('hash')),
                        formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    ).then(function (response) {
                        ////console.log('SUCCESS!!');
                        //console.log("enter after 2nd condition");
                        axiosThis.cardloader = false
                        axiosThis.DisplayData = response.data
                        sessionStorage.setItem('DisplayData', JSON.stringify(axiosThis.DisplayData));
                        // aadhae
                        axiosThis.aadharFile = axiosThis.DisplayData[0].adharimg
                        ////console.log("AL", axiosThis.aadharFile)
                        // axiosThis.aadhaApi = axiosThis.aadharFile.split('/').slice(-4).join('/');
                        // axiosThis.aadhaApi = axiosThis.aadharFile.split('/').slice(-5).join('/');
                        // axiosThis.aadhaApi = `${api.api_url}${axiosThis.aadharFile.split('/ap-backend/')[1]}`
                        if (axiosThis.aadharFile) {
                            // Check if axiosThis.panApi is defined
                            const splitResult = axiosThis.aadharFile.split('/ap-backend/');
                            //console.log("splitResult", splitResult);
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.aadhaApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.panApi is undefined
                            //console.error("axiosThis.panApi is undefined.");
                        }
                        sessionStorage.setItem('aadhaApi', axiosThis.aadhaApi);
                        ////console.log("SAL", axiosThis.aadhaApi);
                        // pan
                        axiosThis.panFile = axiosThis.DisplayData[0].panimgloc
                        ////console.log("PL", axiosThis.panFile)
                        //axiosThis.panApi = axiosThis.panFile.split('/').slice(-5).join('/');
                        // axiosThis.panApi = axiosThis.panFile.split('/').slice(-4).join('/');
                        // axiosThis.panApi = axiosThis.panFile.split('/').slice(-5).join('/');
                        // axiosThis.panApi = `${api.api_url}${axiosThis.panFile.split('/ap-backend/')[1]}`
                        if (axiosThis.panFile) {
                            // Check if axiosThis.panApi is defined
                            const splitResult = axiosThis.panFile.split('/ap-backend/');
                            //console.log("splitResult", splitResult);
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.panApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.panApi is undefined
                            //console.error("axiosThis.panApi is undefined.");
                        }

                        sessionStorage.setItem('panApi', axiosThis.panApi);
                        ////console.log("SPL", axiosThis.panApi);
                        // bank
                        axiosThis.BankFile = axiosThis.DisplayData[0].bankFileLoc
                        ////console.log("BL", axiosThis.BankFile)
                        // axiosThis.bankApi = axiosThis.BankFile.split('/').slice(-4).join('/');
                        // axiosThis.bankApi = axiosThis.BankFile.split('/').slice(-5).join('/');
                        // axiosThis.bankApi = `${api.api_url}${axiosThis.BankFile.split('/ap-backend/')[1]}`
                        if (axiosThis.BankFile) {
                            // Check if axiosThis.panApi is defined
                            const splitResult = axiosThis.BankFile.split('/ap-backend/');
                            //console.log("splitResult", splitResult);
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.bankApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.panApi is undefined
                            //console.error("axiosThis.panApi is undefined.");
                        }

                        sessionStorage.setItem('bankApi', axiosThis.bankApi);
                        //console.log();
                        ////console.log("SBL", axiosThis.bankApi);
                        // edu
                        // if (axiosThis.type == 'AP') {
                        axiosThis.eduFile = axiosThis.DisplayData[0].educationFileLoc
                        ////console.log("EL", axiosThis.eduFile)
                        //axiosThis.eduApi = axiosThis.eduFile.split('/').slice(-5).join('/');
                        // axiosThis.eduApi = axiosThis.eduFile.split('/').slice(-5).join('/');
                        // axiosThis.eduApi = axiosThis.eduFile.split('/').slice(-4).join('/');
                        // axiosThis.eduApi = `${api.api_url}${axiosThis.eduFile.split('/ap-backend/')[1]}`
                        if (axiosThis.eduFile) {
                            // Check if axiosThis.panApi is defined
                            const splitResult = axiosThis.eduFile.split('/ap-backend/');
                            //console.log("splitResult", splitResult);
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.eduApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.panApi is undefined
                            //console.error("axiosThis.panApi is undefined.");
                        }

                        sessionStorage.setItem('eduApi', axiosThis.eduApi);
                        ////console.log("SEL", axiosThis.eduApi);
                        // }


                        // 
                        axiosThis.digioFile = response.data[0].fileid
                        axiosThis.digioMail = response.data[0].email
                        localStorage.setItem('fileid', axiosThis.digioFile)
                        localStorage.setItem('mailid', axiosThis.digioMail)
                        ////console.log(response['fileid'], response['mailid'])
                        // axiosThis.cardloader = false;
                        axiosThis.$router.push('/preview')
                        localStorage.setItem("stageis", 7)

                        // axiosThis.step = 7;
                        // axios.Slidervalue = 84

                    })
                        .catch((error) => {
                            console.log(error);
                        });
                }
                else {
                    this.cardloader = false;
                    this.toasttext = 'Please Upload all files in Image Format'
                    this.success = false;
                    this.failure = true;
                    this.snackbar = true

                    //this.$v.$reset()

                    // }
                }



            } else if ((this.acctype == 'RA') && (this.panStatus == 'false') && (this.pennyVerify == 'No')) {
                // //alert("edu,bank,pan")
                ////console.log("RA1condition");
                if (this.bankFile && this.panFile) {
                    this.cardloader = true;

                    //this.$v.$reset()
                    // var axiosThis = this
                    let formData = new FormData();
                    // ////console.log(this.panFile, this.aadharFile, this.bankFile, this.educationFiler)

                    formData.append('panFile', this.panFile);
                    // formData.append('aadharFile', this.aadharFile);
                    formData.append('bankFile', this.bankFile);
                    // formData.append('educationFile', this.educationFiler);
                    formData.append('mob', atob(localStorage.getItem('hash')));

                    ////console.log('>> formData >> ', formData);
                    const axiosThis = this

                    // You should have a server side REST API 
                    axios.post(api.api_url + '/fileUpload?mob=' + atob(localStorage.getItem('hash')),
                        formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    ).then(function (response) {
                        ////console.log('SUCCESS!!');
                        ////console.log("123321", response)
                        // const axiosThis = this
                        axiosThis.cardloader = false

                        axiosThis.DisplayData = response.data
                        sessionStorage.setItem('DisplayData', JSON.stringify(axiosThis.DisplayData));
                        // aadhae
                        axiosThis.aadharFile = axiosThis.DisplayData[0].adharimg
                        ////console.log("AL", axiosThis.aadharFile)
                        // axiosThis.aadhaApi = axiosThis.aadharFile.split('/').slice(-5).join('/');
                        // axiosThis.aadhaApi = axiosThis.aadharFile.split('/').slice(-4).join('/');
                        // axiosThis.aadhaApi = `${api.api_url}${axiosThis.aadharFile.split('/ap-backend/')[1]}`
                        if (axiosThis.aadharFile) {
                            // Check if axiosThis.panApi is defined
                            const splitResult = axiosThis.aadharFile.split('/ap-backend/');
                            //console.log("splitResult", splitResult);
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.aadhaApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.panApi is undefined
                            //console.error("axiosThis.panApi is undefined.");
                        }
                        sessionStorage.setItem('aadhaApi', axiosThis.aadhaApi);
                        ////console.log("SAL", axiosThis.aadhaApi);
                        // pan
                        axiosThis.panFile = axiosThis.DisplayData[0].panimgloc
                        ////console.log("PL", axiosThis.panFile)
                        //axiosThis.panApi = axiosThis.panFile.split('/').slice(-5).join('/');
                        // axiosThis.panApi = axiosThis.panFile.split('/').slice(-5).join('/');
                        // axiosThis.panApi = axiosThis.panFile.split('/').slice(-4).join('/');
                        // axiosThis.panApi = `${api.api_url}${axiosThis.panFile.split('/ap-backend/')[1]}`
                        if (axiosThis.panFile) {
                            // Check if axiosThis.panApi is defined
                            const splitResult = axiosThis.panFile.split('/ap-backend/');
                            //console.log("splitResult", splitResult);
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.panApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.panApi is undefined
                            //console.error("axiosThis.panApi is undefined.");
                        }

                        sessionStorage.setItem('panApi', axiosThis.panApi);
                        ////console.log("SPL", axiosThis.panApi);
                        // bank
                        axiosThis.BankFile = axiosThis.DisplayData[0].bankFileLoc
                        ////console.log("BL", axiosThis.BankFile)
                        // axiosThis.bankApi = axiosThis.BankFile.split('/').slice(-5).join('/');
                        // axiosThis.bankApi = axiosThis.BankFile.split('/').slice(-4).join('/');
                        if (axiosThis.BankFile) {
                            // Check if axiosThis.panApi is defined
                            const splitResult = axiosThis.BankFile.split('/ap-backend/');
                            //console.log("splitResult", splitResult);
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.bankApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.panApi is undefined
                            //console.error("axiosThis.panApi is undefined.");
                        }

                        // axiosThis.bankApi = `${api.api_url}${axiosThis.BankFile.split('/ap-backend/')[1]}`
                        sessionStorage.setItem('bankApi', axiosThis.bankApi);
                        ////console.log("SBL", axiosThis.bankApi);
                        // edu
                        // if (axiosThis.type == 'AP') {
                        //   axiosThis.eduFile = axiosThis.DisplayData[0].educationFileLoc
                        //   ////console.log("EL", axiosThis.eduFile)
                        //   //axiosThis.eduApi = axiosThis.eduFile.split('/').slice(-5).join('/');
                        // axiosThis.eduApi = axiosThis.eduFile.split('/').slice(-5).join('/');
                        //   axiosThis.eduApi = `${api.api_url}${axiosThis.eduApi}`
                        // sessionStorage.setItem('eduApi', axiosThis.eduApi);
                        //   ////console.log("SEL", axiosThis.eduApi);
                        // }


                        // 
                        axiosThis.digioFile = response.data[0].fileid
                        axiosThis.digioMail = response.data[0].email
                        localStorage.setItem('fileid', axiosThis.digioFile)
                        localStorage.setItem('mailid', axiosThis.digioMail)
                        ////console.log(response['fileid'], response['mailid'])
                        axiosThis.cardloader = false;
                        axiosThis.$router.push('/preview')
                        localStorage.setItem("stageis", 7)

                        // axiosThis.step = 7;
                        // axios.Slidervalue = 84

                    })
                        .catch((error) => {
                            console.log(error);
                        });

                }
            } else if ((this.acctype == 'RA') && (this.panStatus == 'false') && (this.pennyVerify == 'yes')) {
                // //alert("edu,bank")
                //console.log("RA2condition");
                if (this.panFile) {
                    this.cardloader = true;

                    //this.$v.$reset()
                    // var axiosThis = this
                    let formData = new FormData();
                    // ////console.log(this.panFile, this.aadharFile, this.bankFile, this.educationFiler)

                    formData.append('panFile', this.panFile);
                    // formData.append('aadharFile', this.aadharFile);
                    // formData.append('bankFile', this.bankFile);
                    // formData.append('educationFile', this.educationFiler);
                    formData.append('mob', atob(localStorage.getItem('hash')));

                    ////console.log('>> formData >> ', formData);
                    const axiosThis = this

                    // You should have a server side REST API 
                    axios.post(api.api_url + '/fileUpload?mob=' + atob(localStorage.getItem('hash')),
                        formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    ).then(function (response) {
                        ////console.log('SUCCESS!!');
                        // const axiosThis = this
                        axiosThis.cardloader = false

                        axiosThis.DisplayData = response.data
                        sessionStorage.setItem('DisplayData', JSON.stringify(axiosThis.DisplayData));
                        // aadhae
                        axiosThis.aadharFile = axiosThis.DisplayData[0].adharimg
                        ////console.log("AL", axiosThis.aadharFile)
                        // axiosThis.aadhaApi = axiosThis.aadharFile.split('/').slice(-5).join('/');
                        // axiosThis.aadhaApi = axiosThis.aadharFile.split('/').slice(-4).join('/');
                        // axiosThis.aadhaApi = `${api.api_url}${axiosThis.aadharFile.split('/ap-backend/')[1]}`
                        if (axiosThis.aadharFile) {
                            // Check if axiosThis.panApi is defined
                            const splitResult = axiosThis.aadharFile.split('/ap-backend/');
                            //console.log("splitResult", splitResult);
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.aadhaApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.panApi is undefined
                            //console.error("axiosThis.panApi is undefined.");
                        }
                        sessionStorage.setItem('aadhaApi', axiosThis.aadhaApi);
                        ////console.log("SAL", axiosThis.aadhaApi);
                        // pan
                        axiosThis.panFile = axiosThis.DisplayData[0].panimgloc
                        ////console.log("PL", axiosThis.panFile)
                        //axiosThis.panApi = axiosThis.panFile.split('/').slice(-5).join('/');
                        // axiosThis.panApi = axiosThis.panFile.split('/').slice(-5).join('/');
                        // axiosThis.panApi = axiosThis.panFile.split('/').slice(-4).join('/');
                        // axiosThis.panApi = `${api.api_url}${axiosThis.panFile.split('/ap-backend/')[1]}`
                        if (axiosThis.panFile) {
                            // Check if axiosThis.panApi is defined
                            const splitResult = axiosThis.panFile.split('/ap-backend/');
                            //console.log("splitResult", splitResult);
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.panApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.panApi is undefined
                            //console.error("axiosThis.panApi is undefined.");
                        }

                        sessionStorage.setItem('panApi', axiosThis.panApi);
                        ////console.log("SPL", axiosThis.panApi);
                        // bank
                        // axiosThis.BankFile = axiosThis.DisplayData[0].bankFileLoc
                        // ////console.log("BL", axiosThis.BankFile)
                        // axiosThis.bankApi = axiosThis.BankFile.split('/').slice(-5).join('/');
                        // axiosThis.bankApi = `${api.api_url}${axiosThis.bankApi}`
                        // sessionStorage.setItem('bankApi', axiosThis.bankApi);
                        // ////console.log("SBL", axiosThis.bankApi);
                        // edu
                        // if (axiosThis.type == 'AP') {
                        //   axiosThis.eduFile = axiosThis.DisplayData[0].educationFileLoc
                        //   ////console.log("EL", axiosThis.eduFile)
                        //   //axiosThis.eduApi = axiosThis.eduFile.split('/').slice(-5).join('/');
                        // axiosThis.eduApi = axiosThis.eduFile.split('/').slice(-5).join('/');
                        //   axiosThis.eduApi = `${api.api_url}${axiosThis.eduApi}`
                        // sessionStorage.setItem('eduApi', axiosThis.eduApi);
                        //   ////console.log("SEL", axiosThis.eduApi);
                        // }


                        // 
                        axiosThis.digioFile = response.data[0].fileid
                        axiosThis.digioMail = response.data[0].email
                        localStorage.setItem('fileid', axiosThis.digioFile)
                        localStorage.setItem('mailid', axiosThis.digioMail)
                        ////console.log(response['fileid'], response['mailid'])
                        axiosThis.cardloader = false;
                        // axiosThis.step = 7;
                        // axios.Slidervalue = 84
                        axiosThis.$router.push('/preview')
                        localStorage.setItem("stageis", 7)

                    })
                        .catch((error) => {
                            console.log(error);
                        });

                }


            } else if ((this.acctype == 'RA') && (this.panStatus == 'true') && (this.pennyVerify == 'No')) {
                // //alert("edu,bank")
                ////console.log("RA3condition");
                if (this.bankFile) {
                    this.cardloader = true;

                    //this.$v.$reset()
                    let formData = new FormData();
                    // ////console.log(this.panFile, this.aadharFile, this.bankFile, this.educationFiler)

                    // formData.append('panFile', this.panFile);
                    // formData.append('aadharFile', this.aadharFile);
                    formData.append('bankFile', this.bankFile);
                    // formData.append('educationFile', this.educationFiler);
                    formData.append('mob', atob(localStorage.getItem('hash')));

                    ////console.log('>> formData >> ', formData);
                    // const axiosThis = this
                    const axiosThis = this

                    // You should have a server side REST API 
                    axios.post(api.api_url + '/fileUpload?mob=' + atob(localStorage.getItem('hash')),
                        formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    ).then(function (response) {
                        ////console.log('SUCCESS!!');
                        axiosThis.cardloader = false

                        axiosThis.DisplayData = response.data
                        ////console.log('SUCCESS!!');

                        sessionStorage.setItem('DisplayData', JSON.stringify(axiosThis.DisplayData));
                        // aadhae
                        axiosThis.aadharFile = axiosThis.DisplayData[0].adharimg
                        ////console.log("AL", axiosThis.aadharFile)
                        // axiosThis.aadhaApi = axiosThis.aadharFile.split('/').slice(-4).joi   n('/');
                        // https://apbe.zebull.in/ap-backend/static/DigiDownload/CFKPJ0944R/PANImage_CFKPJ0944R.jpg
                        // //console.log("aadharFile",axiosThis.aadharFile.split('/static'));
                        // axiosThis.aadhaApi = axiosThis.aadharFile.split('/').slice(-5).join('/');
                        // axiosThis.aadhaApi = `${api.api_url}${axiosThis.aadharFile.split('/ap-backend/')[1]}`
                        if (axiosThis.aadharFile) {
                            // Check if axiosThis.panApi is defined
                            const splitResult = axiosThis.aadharFile.split('/ap-backend/');
                            //console.log("splitResult", splitResult);
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.aadhaApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.panApi is undefined
                            //console.error("axiosThis.panApi is undefined.");
                        }
                        sessionStorage.setItem('aadhaApi', axiosThis.aadhaApi);
                        ////console.log("SAL", axiosThis.aadhaApi);
                        // pan
                        axiosThis.panFile = axiosThis.DisplayData[0].panimgloc
                        ////console.log("PL", axiosThis.panFile)
                        //axiosThis.panApi = axiosThis.panFile.split('/').slice(-5).join('/');
                        // axiosThis.panApi = axiosThis.panFile.split('/').slice(-5).join('/');
                        // axiosThis.panApi = axiosThis.panFile.split('/').slice(-4).join('/');
                        // axiosThis.panApi = `${api.api_url}${axiosThis.panFile.split('/ap-backend/')[1]}`
                        if (axiosThis.panFile) {
                            // Check if axiosThis.panApi is defined
                            const splitResult = axiosThis.panFile.split('/ap-backend/');
                            //console.log("splitResult", splitResult);
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.panApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.panApi is undefined
                            //console.error("axiosThis.panApi is undefined.");
                        }


                        sessionStorage.setItem('panApi', axiosThis.panApi);
                        ////console.log("SPL", axiosThis.panApi);
                        // bank
                        axiosThis.BankFile = axiosThis.DisplayData[0].bankFileLoc
                        ////console.log("BL", axiosThis.BankFile)
                        // axiosThis.bankApi = axiosThis.BankFile.split('/').slice(-4).join('/');
                        // axiosThis.bankApi = axiosThis.BankFile.split('/').slice(-5).join('/');
                        // axiosThis.bankApi = `${api.api_url}${axiosThis.BankFile.split('/ap-backend/')[1]}`
                        if (axiosThis.BankFile) {
                            // Check if axiosThis.panApi is defined
                            const splitResult = axiosThis.BankFile.split('/ap-backend/');
                            //console.log("splitResult", splitResult);
                            if (splitResult.length > 1) {
                                // Check if the split operation was successful
                                axiosThis.bankApi = `${api.api_url}${splitResult[1]}`;
                            } else {
                                // Handle the case where the split operation failed
                                //console.error("Split operation failed.");
                            }
                        } else {
                            // Handle the case where axiosThis.panApi is undefined
                            //console.error("axiosThis.panApi is undefined.");
                        }
                        sessionStorage.setItem('bankApi', axiosThis.bankApi);
                        ////console.log("SBL", axiosThis.bankApi);
                        // edu
                        // if (axiosThis.type == 'AP') {
                        //   axiosThis.eduFile = axiosThis.DisplayData[0].educationFileLoc
                        //   ////console.log("EL", axiosThis.eduFile)
                        //   //axiosThis.eduApi = axiosThis.eduFile.split('/').slice(-5).join('/');
                        // axiosThis.eduApi = axiosThis.eduFile.split('/').slice(-5).join('/');
                        //   axiosThis.eduApi = `${api.api_url}${axiosThis.eduApi}`
                        // sessionStorage.setItem('eduApi', axiosThis.eduApi);
                        //   ////console.log("SEL", axiosThis.eduApi);
                        // }


                        // 
                        axiosThis.digioFile = response.data[0].fileid
                        axiosThis.digioMail = response.data[0].email
                        localStorage.setItem('fileid', axiosThis.digioFile)
                        localStorage.setItem('mailid', axiosThis.digioMail)
                        ////console.log(response['fileid'], response['mailid'])
                        axiosThis.cardloader = false;
                        // axiosThis.step = 7;
                        // axios.Slidervalue = 84
                        //console.log
                        axiosThis.$router.push('/preview')
                        localStorage.setItem("stageis", 7)

                    })
                        .catch((error) => {
                            console.log(error);
                        });

                }


            } else {
                //console.log("ERROR IN UPLOADING");
            }





        },
        handleKeyUp(event) {

            if (this.panFile || this.educationFiler || this.bankFile) {
                // if (this.otpdisp && !this.emailDisp  && !this.isShowing && !this.emailOtpDisp  ) {
                if (event.key === 'Enter') {
                    // alert("fghj")
                    this.fileUpload();
                    ////console.log("verify mobile Enter pressed");

                    // this.otpVerify();
                    // this.verifyEmail();
                }

                // }
            }
        },

    },
    watch: {
        loader() {
            if (this.mobile_num) {
                if (this.mobile_num.length == 10) {
                    //this.errorMobNumber=false
                    const l = this.loader
                    this[l] = !this[l]

                    setTimeout(() => (this[l] = false), 2000)

                    this.loader = null
                    if (this.emailDisp != true) {
                        this.loadertext = "OTP Sent"
                        //this.$v.$reset();
                        setTimeout(() => (this.mobdisp = false, this.otpdisp = false, this.timerEnabled = true), 1000)
                    }
                }
            }

        },
        panLoader() {
            const l = this.panLoader
            this[l] = !this[l]

            setTimeout(() => (this[l] = false), 2000)

            this.panLoader = null
        },
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
        timerEnabled(value) {
            if (value) {
                setTimeout(() => {
                    this.timerCount--;
                }, 1000);
            }
        },
        timerCount: {
            handler(value) {

                if (value > 0 && this.timerEnabled) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }
                if (value == 0) {
                    this.timerEnabled = false
                    this.resendText = true
                }

            },
            immediate: true // This ensures the watcher is triggered upon creation
        },


    },
    computed: {


    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.handleKeyUp);
    },
    mounted() {
        document.addEventListener('keyup', this.handleKeyUp);

        this.acctype = sessionStorage.getItem("acctype")
        //console.log(this.acctype);
        this.panStatus = sessionStorage.getItem("panStatus")
        // console.log(this.panStatus)
        this.pennyVerify = "No"
        //console.log(this.pennyVerify)

        // if(sessionStorage.getItem("this.images")){
        //     this.images.push(sessionStorage.getItem("this.images"))
        //     this.edufileprev = true
        // }else if(sessionStorage.getItem("this.images1")){
        //     this.images1.push(sessionStorage.getItem("this.images1"))
        //     this.panfileprev = true
        // }else if(sessionStorage.getItem("this.images2")) {
        //     this.images2.push(sessionStorage.getItem("this.images2"))
        //     this.bankfileprev = true
        // }



        // //console.log("this.image2",sessionStorage.getItem("this.images2"))
        this.mainurl = api.api_url;

    },
}

</script>

<style lang="scss">
.card-sty1 {
    // border: 1px solid #F2F2F2 !important;
    box-shadow: 0px 20px 25px 20px rgba(175, 175, 175, 0.2) !important;
    overflow: hidden;
    border-radius: 34px !important;
}

.body-card {
    height: 100vh !important;
    overflow-y: scroll !important;
}

.bottomFixButtionComponent {
    position: fixed;
    bottom: -180px;
    padding-top: 10px;
    background: yellowgreen;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    /* box-shadow: 0px -4px 3px rgb(27 27 24 / 75%); */
}

.cards {
    width: 100%;
    height: 230px;
    border: 2px solid #b8b7b7;
    border-style: dashed;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

#thumb-image {
    /* float: left; */
    width: 100%;
    height: 230px;

    padding: 5px;
}

.theme--light.v-input input,
.theme--light.v-input textarea {
    color: rgb(3 3 3 / 87%);
    font-weight: 530;
    font-size: 14px;
}

.loader {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 1;
    width: 100%;
    height: 100vh;
}
</style>


