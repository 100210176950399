<template>
    <div>
        <v-snackbar v-if="isLesserThanResolution" v-model="snackbar" :timeout="timeout" absolute top color="success"
            outlined right class="">
            <!-- <v-card> -->
            <v-icon color="green" v-if="success">
                "mdi mdi-check-circle
            </v-icon>
            <v-icon color="red" v-if="failure">
                mdi mdi-close-circle
            </v-icon>
            {{ toasttext }}

            <template v-slot:action="{ attrs }">
                <v-btn icon color="green" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi mdi-close</v-icon>
                </v-btn>
            </template>
            <!-- </v-card> -->
        </v-snackbar>
        <v-snackbar v-else v-model="snackbar" :timeout="timeout" absolute bottom color="success" outlined class="">
            <!-- <v-card> -->
            <v-icon color="green" v-if="success">
                "mdi mdi-check-circle
            </v-icon>
            <v-icon color="red" v-if="failure">
                mdi mdi-close-circle
            </v-icon>
            {{ toasttext }}

            <template v-slot:action="{ attrs }">
                <v-btn icon color="green" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi mdi-close</v-icon>
                </v-btn>
            </template>
            <!-- </v-card> -->
        </v-snackbar>
        <v-snackbar v-if="isLesserThanResolution" v-model="snackbar1" :timeout="timeout" absolute top color="red" outlined
            right class="">
            <!-- <v-card> -->
            <v-icon color="green" v-if="success">
                "mdi mdi-check-circle
            </v-icon>
            <v-icon color="red" v-if="failure">
                mdi mdi-close-circle
            </v-icon>
            {{ toasttext }}

            <template v-slot:action="{ attrs }">
                <v-btn icon color="red" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi mdi-close</v-icon>
                </v-btn>
            </template>
            <!-- </v-card> -->
        </v-snackbar>
        <v-snackbar v-else v-model="snackbar1" :timeout="timeout" absolute bottom color="red" outlined class="">
            <!-- <v-card> -->
            <v-icon color="green" v-if="success">
                "mdi mdi-check-circle
            </v-icon>
            <v-icon color="red" v-if="failure">
                mdi mdi-close-circle
            </v-icon>
            {{ toasttext }}

            <template v-slot:action="{ attrs }">
                <v-btn icon color="red" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi mdi-close</v-icon>
                </v-btn>
            </template>
            <!-- </v-card> -->
        </v-snackbar>
        <v-container class="body-card">
            <v-card width="" height="" class="card-sty1  ">
                <v-progress-linear absolute color='#0037B7' height="12" value="36"></v-progress-linear>
                <v-toolbar color="transparent" dense flat class="mt-5 mb-3">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <img :src="require('@/assets/Zebu Logo.svg')" class="ml-1" contain width="90px">

                        </v-col>
                        <v-col cols="3" class="d-none d-lg-block">
                            <!-- <p v-if="!dataFromDigiCame" class="mt-5 mb-0 font-weight-bold sec-ff" style="color:#0037B7;">
                                Digilocker, Details 1 of 2</p>
                            <p v-if="dataFromDigiCame" class="mt-5 mb-0 font-weight-bold sec-ff" style="color:#0037B7;">
                                Digilocker, Details 2 of 2</p> -->
                        </v-col>
                        <v-col class="stageselect" cols="3">
                            <!-- {{ stageis }} -->
                            <v-select outlined style="" dense @change="stageToo()" item-text="val" item-value="key"
                                item-disabled=disable :items="stagesitemsis" v-model="stageis" flat solo
                                background-color="#ffffff" class="txtfield elevation-0 rounded-pill mr-4 ml-auto mt-3 "
                                label="Stage" hide-details append-icon="mdi-chevron-down"></v-select>
                        </v-col>
                    </v-row>
                </v-toolbar>
                <v-row class="px-8">
                    <v-col cols="6" class="d-none d-lg-block">
                        <v-card width="100%" class="rounded-0 elevation-0 ">
                            <img v-for="(image, index) in images" :key="index" :src="image"
                                :style="{ display: index === currentIndex ? 'block' : 'none' }" style="height: 26em;
  display: flex;
  align-items: center;
  justify-content: center" width="100%" />
                        </v-card>
                    </v-col>
                    <v-col class="pa-0 pl-2 mt-1 cols-sm-12">
                        <!-- <v-card v-if="true" fill-height style="background-color: rgba(255, 255, 255, 0.733);  "
                                height="504" width="" class="rounded-0 elevation-0 loader">
                                <div class="" style="margin-top: 138px;">
                                    <v-progress-linear class="blocktext" indeterminate color="cyan"></v-progress-linear>
                                    <p class="blocktext text-center subtitle-1  font-weight-bold">Fetchiing data from
                                        DIGILOCKER</p>
                                </div>

                            </v-card> -->
                        <!-- kl {{ kycLoader }} dd {{ dataFromDigiCame }} -->
                        <v-card v-if="kycLoader" style="background-color: rgba(255, 255, 255, 0.733);  " height="504"
                            width="" class="rounded-0 elevation-0 ">
                            <div class="" style="margin-top: 138px;">
                                <v-progress-circular class="blocktext" :size="70" color="primary"
                                    indeterminate></v-progress-circular>
                                <p class="blocktext text-center subtitle-1  font-weight-bold">Fetchiing data from
                                    DIGILOCKER</p>
                            </div>

                        </v-card>
                        <v-card v-if="changeloader" style="background-color: rgba(255, 255, 255, 0.733);  " height="504"
                            width="" class="rounded-0 elevation-0 ">
                            <div class="" style="margin-top: 138px;">
                                <v-progress-circular class="blocktext" :size="70" color="primary"
                                    indeterminate></v-progress-circular>
                                <p class="blocktext text-center subtitle-1  font-weight-bold">Fetchiing data from
                                    DIGILOCKER</p>
                            </div>

                        </v-card>
                        <v-card v-else-if="!kycLoader && dataFromDigiCame" width="100%"
                            class="rounded-0 elevation-0 scrollbar" style="overflow: scroll;">
                            <div class="px-8" style="overflow: inline-flex; overflow: hidden;">
                                <v-row class="mt-6 px-3">
                                    <v-avatar size="50" style="background-color: #000000">
                                        <span class="white--text text-h5">{{ name[0] }}</span>
                                    </v-avatar>
                                    <v-col class="pa-0 px-5">
                                        <h2 style="font-size: 16px; color: #000000">{{ name }}</h2>
                                        <p>{{ aadharNo }}</p>
                                    </v-col>
                                </v-row>
                                <v-row class="pa-0 mt-5">
                                    <!-- <v-img :src="require('@/assets/calendar.svg')" contain color='#004B55' class="mt-4" height="23" -->
                                    <!-- max-width="24" />/ -->
                                    <v-col class="pa-3 pb-0" cols="6">
                                        <v-text-field v-model="dob" class="pa-0 ma-0" label="DOB" color='#004B55' readonly
                                            disabled></v-text-field>
                                    </v-col>
                                    <!-- <v-img :src="require('@/assets/gend.svg')" contain class="mt-4" height="23" max-width="24" /> -->
                                    <v-col class="pa-3 pb-0  " cols=6>
                                        <v-text-field class="pa-0 ma-0" v-model="gender" disabled color='#004B55' dense
                                            label="Gender"></v-text-field>
                                        <!--   :hide-details="true" -->
                                        <!-- <v-text-field dense label=""></v-text-field> -->
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <!-- <v-img :src="require('@/assets/father.svg')" contain class="mt-2 ml-3" height="20" max-width="24" /> -->
                                    <v-col class="pa-0 px-2">
                                        <v-form ref="form" v-model="valid" lazy-validation>
                                            <v-text-field v-if="fatherName != ''" v-model="fatherName" disabled
                                                color='#004B55' dense label="Father/Spouse Name"></v-text-field>

                                            <v-text-field v-else v-on:keyup="faternamevali()" class="lable"
                                                :rules="[rulesforname]" v-model="fatherNameval" color='black' dense required
                                                @input="$v.fatherNameval.$touch()" @blur="$v.fatherNameval.$touch()"
                                                label="Father/Spouse Name"></v-text-field>
                                        </v-form>
                                    </v-col>
                                </v-row>
                                <p class="mt-6 mb-0" style="font-size: 14px; font-weight: 500">
                                    Your Address
                                </p>
                                <v-card
                                    style="border-radius: 20px; border-style: dashed; border: 1px solid rgb(138, 138, 138);"
                                    class="pb-3 px-6 pt-3 elevation-0">
                                    <p class="ma-0" style="font-size: 13px; font-weight: 500    ;">
                                        <!-- text-align: center; -->
                                        {{ address }}
                                    </p>
                                </v-card>
                                <v-row class=" mb-5 mt-5">
                                    <v-col lg="6" md="6" sm="6" class="fltbtn1">
                                        <v-btn style="width: 100%;" rounded color="black" elevation=""
                                            class=" py-6 otpbtn white--text font-weight-bold body-2"
                                            @click="changeaadhar()">Change
                                        </v-btn>
                                    </v-col>
                                    <v-col lg="6" md="6" sm="6" class="fltbtn">
                                        <v-btn style="width: 100%;" rounded color="black" elevation=""
                                            class=" py-6 otpbtn white--text  font-weight-bold body-2"
                                            @click="Aadarpage()">Continue
                                        </v-btn>

                                    </v-col>
                                </v-row>


                            </div>
                        </v-card>
                        <v-card v-else width="100%" class="rounded-0 elevation-0">

                            <p class="title font-weight-bold mb-0 mt-2" style="">Connect Digilocker</p>

                            <p class="   mt-1 mb-6" style="font-size: 15px; color: #808080 !important;">Share Aadhar details
                                from Digilocker</p>

                            <p class="mr-3"
                                style="font-size: 17px; color: #000000 !important; margin-top: 62px; margin-bottom: 58px">
                                agree
                                that have read
                                the Aadhaar user consent document and voluntarily consent to share my Aadhaar information
                                with zebu for conducting KYC for opening a trading and Demat account adhering to KRA
                                regulations (2011) and PMLA guidelines (2002).</p>
                            <!-- <v-btn style="width: 93%;" color="black" elevation="2" small
                                class=" py-6 otpbtn white--text mt-2 font-weight-bold body-2" rounded
                                >Yes, I agree
                            </v-btn> -->
                            <v-btn style="width: 93%;"
                                class="fltbtn py-6 otpbtn white--text mt-2 font-weight-bold body-2 mb-4" rounded
                                color="black" elevation="2" small :loading="loading2" :disabled="loading2"
                                @click="connectDigio()">
                                YES, I agree
                                <template v-slot:loader>
                                    <span>Loading...</span>
                                </template>
                            </v-btn>
                        </v-card>

                    </v-col>
                </v-row>
            </v-card>
        </v-container>
    </div>
</template>
<script>
import axios from 'axios';
import api from '@/api';
import imagePath  from '../assets/inv1.svg'
import imagePath1  from '../assets/inv2.svg'
import imagePath2  from '../assets/inv3.svg'
import imagePath3  from '../assets/inv4.svg'
import imagePath4  from '../assets/inv5.svg'
export default {
    data: () => ({
        currentIndex: 0,
        images: [
      // 'http://zebu-ekcy.web.app/img/inv3.bf76f1c4.svg',
      // 'http://zebu-ekcy.web.app/img/inv5.fb7c3474.svg',
      // 'http://zebu-ekcy.web.app/img/trd3.2bc52f23.svg',
      // 'http://zebu-ekcy.web.app/img/inv4.6e5ffeda.svg',
      // 'http://zebu-ekcy.web.app/img/inv4.6e5ffeda.svg',
      imagePath,imagePath1,imagePath2,imagePath3,imagePath4
      // 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',

      // 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',

      // 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',

    ],
        // loader: null,
        // loading: false,

        valid: true,
        rulesforname: 'Required',
        loading2: false,
        // Sign Up
        mobile_num: null,
        otp: '',
        stageis: 3,
        stagesitemsis: [{ key: 1, val: 'Sign up' }, { key: 2, val: 'Identity verification' }, { key: 3, val: 'Digilocker' }, { key: 4, val: 'Bank details' }, { key: 5, val: "Segment selection" }, { key: 6, val: 'Document upload' }, { key: 7, val: 'e-sign' },],

        otpdisp: true,
        snackbar: false,
        snackbar1: false,
        timerEnabled: false,
        timerCount: 120,
        resendText: false,
        toasttext: 'null',
        cardloader: false,
        errorOTPDisp: false,
        numberStart: true,
        otpstart: true,
        otptext: '',
        errorDescPAN: '',
        panbtn: false,
        dataFromDigiCame: false,
        // test\
        mainurl: null,
        accountTypeItems: ['Saving Account', 'Current Account'],
        accountType: null,
        accNo: null,
        ifsc: null,
        retrybank: false,

        retrybankacc: false,
        retryCount: 0,
        fileattach1st: false,
        opencam: false,
        // cam
        isCameraOpen: false,
        isPhotoTaken: false,
        isShotPhoto: false,
        isLoading: false,
        link: '#',
        photoData: null,
        localHash: null,
        dialog1: false,
        panStatus: null,
        pennyVerify: '',
        acctype: null,
        eduFile: false,
        DisplayData: [],
        aadhaApi: '',
        fatherNameval: '',
        corFather: '',
        bankApi: '',
        Slidervalue: 1,
        eduApi: '',
        // fatherNameval : '',
        // from kyc
        loader: null,
        panLoader: null,
        loading: false,
        // mobile_num: null,
        //errorMobNumber:false,
        logstatus: false,
        reference: null,
        // timerCount: 120,
        // timerEnabled: false,
        // resendText: false,
        email: null,
        emailDisp: false,
        emailOtpDisp: false,
        otpemail: null,
        // otp: null,
        checkbox: false,
        invalid: null,
        text: `You will receive an OTP on your number`,
        loadertext: "Verify",
        timeout: 1000,
        // otpdisp: true,
        mobdisp: true,
        isShowing: false,
        activePicker: null,
        date: null,
        menu: false,
        panDetaildisp: true,
        panFetchDetail: false,
        panInput: null,
        // accNo:s null,
        // ifsc: null,
        bank_name: null,
        bank_branch: null,
        bank_micr: null,
        enabledRegisteredAP: false,
        regMemberName: null,
        exchangeSelect: [],
        tradingExperience: null,
        qualificationList: ['Highschool', 'Graduation', 'Post Graduation', 'Doctrate', 'Prof. Degree', 'Under High School', 'Illiterate-Other'],
        occupationList: ['Private Sector', 'Public Sector', 'Government Service', 'Business', 'Professional Agriculturist', 'Retired', 'Housewife', 'Student', 'Forex Dealer', 'Other'],
        qualification: null,
        occupation: null,
        // errorDescPAN: '',
        KycFetchDisp: false,
        dob: null,
        aadharNo: null,
        add1: null,
        add2: null,
        add3: null,
        add4: null,
        address: null,
        country: null,
        district: null,
        state: null,
        pinCode: null,
        fatherName: "",
        gender: null,
        name: null,
        panFile: null,
        // aadharFile:null,
        bankFile: null,
        educationFiler: null,
        kycLoader: false,
        checkboxEsign: false,
        digioFile: null,
        digioMail: null,
        ekycStatus: false,
        step: 1,
        bankverifystatus: null,

        digiInformationShow: false,
        dialog: false,
        isDarkModeEnabled: false,
        // errorOTPDisp: false,
        erroreOTPDisp: false,
        // cardloader: false,
        ifscErrorDesc: false,
        // snackbar: false,
        // toasttext: 'null',
        // timeout: 2000,
        panInputLength: false,
        success: false,
        failure: false,
        // fetchdata : "",s
        datafrom: null,
        changeloader: false,
        rulesfornumber: ""

        // testing: {
        //     "DoB": "12-07-2001",
        //     "aadharNo": "xxxxxxxx0484",
        //     "add1": "S/O Veeraputhirasamy 1/34",
        //     "add2": "",
        //     "add3": "Patchayapuram",
        //     "add4": "Tayilpatti",
        //     "address": "S/O Veeraputhirasamy 1/34  Patchayapuram Tayilpatti Virudhunagar Tamil Nadu 626128",
        //     "country": "India",
        //     "district": "Virudhunagar",
        //     "fatherName": "hjklghjkyhj",
        //     "gender": "Male",
        //     "name": "Sabbu",
        //     "pincode": "626128",
        //     "states": "Tamil Nadu"
        // },

        // querydata: { code: 'abe0cbc404d1ff2a7ac8c66235787073e407c690', state: 'signuprvdjs', hmac: '7d3ccb3a01d9feec3b62b25789c9e475d65350817a7b202f9cbe56777b1bb976' },


    }),


    created() {
        this.startAutoChange();

        let stages = Number(localStorage.getItem("stageis"));
        if (typeof stages === 'number') {
            for (let s = 0; s < this.stagesitemsis.length; s++) {
                this.stagesitemsis[s]['disable'] = this.stagesitemsis[s].key > stages ? true : false;
                //console.log(this.stagesitemsis[s]['disable'], "this.stagesitemsis[s]['disable']")
            }
            if (stages < this.stageis) {
                this.stageis = stages;
                this.stageToo();
            }
        }
        else {
            this.$router.push("/");
        }
        const thresholdResolution = 767;

        const screenWidth = window.innerWidth;

        if (screenWidth < thresholdResolution) {
            this.isLesserThanResolution = true;
        } else {
            this.isLesserThanResolution = false;

        }
    },
    methods: {
        
        changeaadhar() {
            this.kycLoader = true
            this.dataFromDigiCame = false
            this.kycLoader = false


        },
        startAutoChange() {
            setInterval(() => {
                this.currentIndex = (this.currentIndex + 1) % this.images.length;
            }, 5000); // Change every 5 seconds
        },
        stageToo() {
            if (this.stageis == 1) {
                this.$router.push("/");
            }
            else if (this.stageis == 2) {
                this.$router.push("/pan");
            }
            else if (this.stageis == 3) {
                this.$router.push("/aadhar");
            }
            else if (this.stageis == 4) {
                this.$router.push("/bank");
            }
            else if (this.stageis == 5) {
                this.$router.push("/segments");
            }
            else if (this.stageis == 6) {
                this.$router.push("/fileupload");
            }
            else if (this.stageis == 7) {
                this.$router.push("/preview");
            }
            // else if (this.stageis == 8) {
            //     this.$router.push("");
            // }
        },
        connectDigio() {
            this.loader = 'loading2'
            var stateid = "signup" + Math.random().toString(36).substring(7);
            window.location.href =
              "https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=A73859D2&state=" +
              stateid +
              ""; 
            //   for local
            // window.location.href =
            //     "https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=DED7DF29&state=" +
            //     stateid +
            //     "";
            this.kycLoader = true
            // //console.log("Loader start")


        },
        Aadarpage() {
            // this.$v.$touch()
            // alert("hjk")
            this.$refs.form.validate()
            if (this.fatherNameval != '') {
                ////console.log("fatername", this.fatherNameval);
                //console.log("inside api2")
                const axios = require('axios');
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: api.api_url + '/updateFatherName?mob=' + atob(localStorage.getItem('hash')) + '&fName=' + this.fatherNameval,
                    headers: {
                    }
                };
                axios.request(config)
                    .then((response) => {
                        ////console.log(JSON.stringify(response.data));
                        this.corFather = response.data.fatherName
                        ////console.log("corrected father name", this.corFather);
                        localStorage.setItem("stageis", 4)

                        this.$router.push("/bank");
                        // this.step = 4
                        // this.Slidervalue = 48
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
            else {
                //console.log(this.fatherNameval, "in this.fatherName");

                if (this.fatherNameval == '') {
                    this.rulesforname = "Father name required"
                    //console.log(this.fatherName, "in this.fatherName");
                    if (this.fatherName != '') {
                        localStorage.setItem("stageis", 4)

                        this.$router.push("/bank");
                    }
                } else {
                    localStorage.setItem("stageis", 4)

                    this.$router.push("/bank");

                }
                //console.log(this.fatherName, "this.fatherName");
            }
        },
        faternamevali() {
            if (this.fatherNameval == null) {
                this.rulesforname = "required"
            }else{
                this.rulesforname = null

            }
        },
        handleKeyUp(event) {

            if (this.stageis == 3 && this.dataFromDigiCame == false) {
                // if (this.otpdisp && !this.emailDisp  && !this.isShowing && !this.emailOtpDisp  ) {
                if (event.key === 'Enter') {
                    // alert("fghj")
                    this.connectDigio();
                    ////console.log("verify mobile Enter pressed");

                    // this.otpVerify();
                    // this.verifyEmail();
                }

                // }
            }
        },
    },
    watch: {
        // loader() {
        //     if (this.mobile_num) {
        //         if (this.mobile_num.length == 10) {
        //             //this.errorMobNumber=false
        //             const l = this.loader
        //             this[l] = !this[l]

        //             setTimeout(() => (this[l] = false), 2000)

        //             this.loader = null
        //             if (this.emailDisp != true) {
        //                 this.loadertext = "OTP Sent"
        //                 this.$v.$reset();
        //                 setTimeout(() => (this.mobdisp = false, this.otpdisp = false, this.timerEnabled = true), 1000)
        //             }
        //         }
        //     }

        // },
        panLoader() {
            const l = this.panLoader
            this[l] = !this[l]

            setTimeout(() => (this[l] = false), 2000)

            this.panLoader = null
        },
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
        timerEnabled(value) {
            if (value) {
                setTimeout(() => {
                    this.timerCount--;
                }, 1000);
            }
        },
        timerCount: {
            handler(value) {

                if (value > 0 && this.timerEnabled) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }
                if (value == 0) {
                    this.timerEnabled = false
                    this.resendText = true
                }

            },
            immediate: true // This ensures the watcher is triggered upon creation
        },
        loader() {
            const l = this.loader
            this[l] = !this[l]

            setTimeout(() => (this[l] = false), 5000)

            this.loader = null
        },

    },

    beforeDestroy() {
        document.removeEventListener('keyup', this.handleKeyUp);
    },

    computed: {

    },
    mounted() {
        // from kyc

        // this.stageis = localStorage.getItem('stageis')
        document.addEventListener('keyup', this.handleKeyUp);
        //console.log(this.$route.query)
        if (this.$route.query.stageid == 4) {
            // this.step = 2
            // this.Slidervalue = 34
            this.$router.push("/pan");

            this.$route.query.stageid == 0
        }
        var dataFromDigi = this.$route.query.code;
        // var dataFromDigi = this.querydata;
        var ekycstatus = this.$route.query.data;
        // var plan = this.$route.query.id;
        // console.log(plan)
        if (this.$route.query.id) {
            localStorage.setItem('nalp', this.$route.query.id)
        }
        // var data = sessionStorage.getItem('aadharNo')
        // console.log(data);
        // if (data != '') {
        //     this.dataFromDigiCame = true
        //     // this.dataFromDigiCame = true
        //     //console.log("inside DATA '' ");

        //     // this.kycLoader = false
        //     // this.KycFetchDisp = true
        //     this.name = sessionStorage.getItem('name')
        //     this.dob = sessionStorage.getItem('dob')
        //     this.aadharNo = sessionStorage.getItem('aadharNo')
        //     this.address = sessionStorage.getItem('address')
        //     this.add1 = sessionStorage.getItem('add1')
        //     this.add2 = sessionStorage.getItem('add2')
        //     this.add3 = sessionStorage.getItem('add3')
        //     this.add4 = sessionStorage.getItem('add4')
        //     this.country = sessionStorage.getItem('country')
        //     this.district = sessionStorage.getItem('district')
        //     this.fatherName = sessionStorage.getItem('fatherName')
        //     this.pinCode = sessionStorage.getItem('pinCode')
        //     this.state = sessionStorage.getItem('state')
        //     this.gender = sessionStorage.getItem('gender')
        // }
        this.mobile_num = atob(localStorage.getItem('hash'))

        if (dataFromDigi) {
            //console.log("inside api ", this.kycLoader);
            // this.kycLoader = false
            // this.dataFromDigiCame = false
            this.changeloader = true
            this.kycLoader = true
            //console.log();
            // this.step = 3;
            // this.Slidervalue = 36
            //console.log("inside KYC ", this.kycLoader, this.dataFromDigiCame);



            // this.dataFromDigiCame = true
            //console.log("inside KYC ", this.kycLoader, this.dataFromDigiCame,);
            this.mobile_num = atob(localStorage.getItem('hash'))


            var config = {
                method: 'post',
                url: api.api_url + '/digiLockerFetch?mob=' + this.mobile_num + '&code=' + dataFromDigi,
                headers: {}
            };
            const axiosThis = this
            axios(config)
                .then(function (response) {

                    // axiosThis.changeloader = false
                    //console.log("121212", JSON.stringify(response.data));
                    //console.log(response.data['name']);
                    var Enter = response.data
                    var Enterapi = Enter.name
                    if (Enterapi) {
                        axiosThis.kycLoader = false
                        axiosThis.changeloader = false
                        //console.log("inside BEFORE RESPONSE ");

                        // var error = response.data['error']

                        // alert(axiosThis.dataFromDigiCame)
                        //console.log(axiosThis.dataFromDigiCame);
                        axiosThis.dataFromDigiCame = true

                        // this.dataFromDigiCame = true
                        // //console.log(axiosThis.dataFromDigiCame);
                        // this.kycLoader = false

                        // axiosThis.dataFromDigiCame = false

                        // axiosThis.kycLoader = true
                        axiosThis.name = response.data['name']
                        axiosThis.dob = response.data['DoB']
                        axiosThis.aadharNo = response.data['aadharNo']
                        axiosThis.address = response.data['address']
                        axiosThis.add1 = response.data['add1']
                        axiosThis.add2 = response.data['add2']
                        axiosThis.add3 = response.data['add3']
                        axiosThis.add4 = response.data['add4']
                        axiosThis.country = response.data['country']
                        axiosThis.district = response.data['district']
                        axiosThis.fatherName = response.data['fatherName']
                        //console.log(axiosThis.fatherName, "axiosThis.fatherName ")
                        axiosThis.pinCode = response.data['pincode']
                        axiosThis.state = response.data['states']
                        axiosThis.gender = response.data['gender']
                        localStorage.setItem("gender",axiosThis.gender)

                        // localStorage.setItem("stageis", 4)
                        // //console.log("1212inside error");
                    } else {

                        let config6 = {
                            method: 'post',
                            maxBodyLength: Infinity,
                            url: api.api_url + '/fetchDigiData?mob=' + axiosThis.mobile_num,
                            headers: {}
                        };
                        axios.request(config6)
                            .then((response) => {
                                // var axiosThis = this
                                axiosThis.kycLoader = false
                                axiosThis.changeloader = false
                                //console.log(JSON.stringify(response.data));
                                axiosThis.datafrom = response.data
                                //console.log(axiosThis.datafrom, "POPOPOP");
                                // axiosThis.kycLoader = false
                                axiosThis.fetchdata = axiosThis.datafrom
                                // axiosThis.kycLoader = false
                                axiosThis.dataFromDigiCame = true
                                axiosThis.name = axiosThis.fetchdata['name']
                                axiosThis.dob = axiosThis.fetchdata['DoB']
                                axiosThis.aadharNo = axiosThis.fetchdata['aadharNo']
                                axiosThis.address = axiosThis.fetchdata['address']
                                axiosThis.add1 = axiosThis.fetchdata['add1']
                                axiosThis.add2 = axiosThis.fetchdata['add2']
                                axiosThis.add3 = axiosThis.fetchdata['add3']
                                axiosThis.add4 = axiosThis.fetchdata['add4']
                                axiosThis.country = axiosThis.fetchdata['country']
                                axiosThis.district = axiosThis.fetchdata['district']
                                axiosThis.fatherName = axiosThis.fetchdata['fatherName']
                                //console.log([axiosThis.fatherName], "axiosThis.fatherName");
                                axiosThis.pinCode = axiosThis.fetchdata['pincode']
                                axiosThis.state = axiosThis.fetchdata['states']
                                axiosThis.gender = axiosThis.fetchdata['gender']
                                localStorage.setItem("gender",axiosThis.gender)
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }


                })
                .catch(function (error) {

                    axiosThis.kycLoader = false
                    axiosThis.changeloader = false
                    console.log("AAAAAAAAA", error);
                    axiosThis.cardloader = false
                    axiosThis.snackbar2 = true
                    axiosThis.toasttext = "Something went wrong please try again later"
                    axiosThis.success = false
                    axiosThis.failure = true
                    axiosThis.panInput = null
                });
        }
        else if (ekycstatus) {
            ////console.log('ekyc status:', ekycstatus);
            this.step = 8;
            this.Slidervalue = 100
            this.ekycStatus = true;
            this.mobile_num = atob(localStorage.getItem('hash'))
            this.digioFile = localStorage.getItem('fileid')
            ////console.log('Disp : ', atob(localStorage.getItem('hash')), this.digioFile)
            var config2 = {
                method: 'post',
                url: api.api_url + '/ekycUpdate?mob=' + this.mobile_num + '&fileid=' + this.digioFile,
                headers: {}
            };
            axios(config2)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else if (!dataFromDigi) {
            let config6 = {
                method: 'post',
                maxBodyLength: Infinity,
                url: api.api_url + '/fetchDigiData?mob=' + this.mobile_num,
                headers: {}
            };
            axios.request(config6)
                .then((response) => {
                    const axiosThis = this
                    axiosThis.kycLoader = false
                    axiosThis.changeloader = false
                    //console.log(JSON.stringify(response.data));
                    axiosThis.datafrom = response.data
                    //console.log(axiosThis.datafrom, "POPOPOP");
                    // axiosThis.kycLoader = false
                    axiosThis.fetchdata = axiosThis.datafrom
                    // axiosThis.kycLoader = false
                    axiosThis.dataFromDigiCame = true
                    axiosThis.name = axiosThis.fetchdata['name']
                    axiosThis.dob = axiosThis.fetchdata['DoB']
                    axiosThis.aadharNo = axiosThis.fetchdata['aadharNo']
                    axiosThis.address = axiosThis.fetchdata['address']
                    axiosThis.add1 = axiosThis.fetchdata['add1']
                    axiosThis.add2 = axiosThis.fetchdata['add2']
                    axiosThis.add3 = axiosThis.fetchdata['add3']
                    axiosThis.add4 = axiosThis.fetchdata['add4']
                    axiosThis.country = axiosThis.fetchdata['country']
                    axiosThis.district = axiosThis.fetchdata['district']
                    axiosThis.fatherName = axiosThis.fetchdata['fatherName']
                    //console.log([axiosThis.fatherName], "axiosThis.fatherName");
                    axiosThis.pinCode = axiosThis.fetchdata['pincode']
                    axiosThis.state = axiosThis.fetchdata['states']
                    axiosThis.gender = axiosThis.fetchdata['gender']
                    localStorage.setItem("gender",axiosThis.gender)

                })
                .catch((error) => {
                    console.log(error);
                });
        }
        // else if (!dataFromDigi) {
        // localStorage.removeItem("hash");
        // localStorage.removeItem("fileid");
        // localStorage.removeItem("sesslog");
        // this.kycLoader = true
        // this.dataFromDigiCame = true
        // const axios = require('axios');
        // axiosThis.dataFromDigiCame = true


        //console.log("inside KYC ", this.kycLoader, this.dataFromDigiCame);
        // var config3 = {
        //   method: 'post',
        //   url: api.api_url + '/fetchDigiData?mob=' + this.mobile_num,
        //   headers: {}
        // };
        // axios(config3)
        //   .then(function (response) {
        //     ////console.log(JSON.stringify(response.data));
        // var axiosThis = this

        //     //console.log("enter fetch")
        //     //console.log(response.data);
        //     // const man = response.data
        //     // //console.log(man);
        //     axiosThis.datafrom = response.data
        //     //console.log(axiosThis.datafrom);
        //     // const axiosThis = this
        //     // axiosThis.fetchdata = response.data
        //     // axiosThis.kycLoader = false
        //     // axiosThis.dataFromDigiCame = true
        //     // axiosThis.name = axiosThis.fetchdata['name']
        //     // axiosThis.dob = axiosThis.fetchdata['DoB']
        //     // axiosThis.aadharNo = axiosThis.fetchdata['aadharNo']
        //     // axiosThis.address = axiosThis.fetchdata['address']
        //     // axiosThis.add1 = axiosThis.fetchdata['add1']
        //     // axiosThis.add2 = axiosThis.fetchdata['add2']
        //     // axiosThis.add3 = axiosThis.fetchdata['add3']
        //     // axiosThis.add4 = axiosThis.fetchdata['add4']
        //     // axiosThis.country = axiosThis.fetchdata['country']
        //     // axiosThis.district = axiosThis.fetchdata['district']
        //     // axiosThis.fatherName = axiosThis.fetchdata['fatherName']
        //     // axiosThis.pinCode = axiosThis.fetchdata['pincode']
        //     // axiosThis.state = axiosThis.fetchdata['states']
        //     // axiosThis.gender = axiosThis.fetchdata['gender']
        //   })
        //   .catch(function (error) {
        //     //console.log(error);
        //   });
        // }
        this.mainurl = api.api_url;



        //   if (this.step == 1) {
        //     this.Slidervalue = 12
        //   }
        //   else if (this.step == 2) {
        //     this.Slidervalue = 24
        //   }else if (this.step == 3) {
        //     this.Slidervalue = 36
        //   }else if (this.step == 4) {
        //     this.Slidervalue = 48
        //   }else if (this.step == 5) {
        //     this.Slidervalue = 60
        //   }else if (this.step == 6) {
        //     this.Slidervalue = 72
        //   }else if (this.step == 7) {
        //     this.Slidervalue = 84
        //   }else if (this.step == 8) {
        //     this.Slidervalue = 100
        //   }

    },
}

</script>
<style lang="scss">
.card-sty1 {
    /* border: 1px solid #F2F2F2 !important; */
    box-shadow: 0px 20px 25px 20px rgba(175, 175, 175, 0.2) !important;
    overflow: hidden;
    border-radius: 30px !important;
    /* margin-left: -1px !important; */
    /* margin-right: 2px !important; */
}

.body-card {
    height: 100vh !important;


    /* overflow-y: scroll !important; */
}

// ::-webkit-scrollbar {
//     display: none;
// }

.loader {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 1;
    width: 100%;

}

.blocktext {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.custom-loader {
    animation: loader 1s infinite;
    display: flex;
}

@-moz-keyframes loader {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes loader {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@-o-keyframes loader {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes loader {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>